import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';

export const PADDING = {
  content: {
    mobile: {
      horizontal: 16,
    },
  },
};

export const CSS_VARIABLES = {
  screenHeight: '--screen-height',
};

export enum LayoutView {
  mobile = 'mobile',
  desktop = 'desktop',
}

export const BREAKPOINT: Record<'mobile' | 'tablet', Breakpoint> = {
  mobile: 'xs',
  tablet: 'sm',
};
