import Fade from '@material-ui/core/Fade';
import { ThemeOptions } from '@material-ui/core';

export const props: ThemeOptions['props'] = {
  MuiTooltip: {
    placement: 'top-start',
    TransitionComponent: Fade,
  },
  MuiPopover: {
    TransitionComponent: Fade,
  },
  MuiButton: {
    disableRipple: true,
  },
  MuiIconButton: {
    disableRipple: true,
  },
  MuiDialog: {
    maxWidth: 'md',
  },
  MuiTab: {
    disableRipple: true,
  },
};
