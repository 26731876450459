import './polyfill';
import 'reflect-metadata';
import 'react-hot-loader';
import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@core/theme';

import history from '@shared/utils/history';
import Notification from '@shared/components/Notification';
import Confirmation from '@shared/components/Confirmation';
import AppContainer from './AppContainer';

import PortalApp from './apps/Portal';
import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';
import Config from '@core/config';
import HTTPClient from '@core/http-client';

const authStore = container.get<AuthStore>(AuthStore.diToken);
const config = container.get<Config>(Config.diToken);
const httpClient = container.get<HTTPClient>(HTTPClient.diToken);

config.initialize();

const {
  apiURL: { origin, pathname },
} = config.get().portal;

httpClient.initialize({
  defaults: {
    baseURL: `${origin}${pathname}`,
  },
  getAccessToken: () => authStore.tokens.access,
  refreshToken: authStore.refreshToken,
});
authStore.initialize();

const theme = createTheme();

const App = () => {
  return (
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Notification />
        <Confirmation />
        <AppContainer>
          <PortalApp />
        </AppContainer>
      </MuiThemeProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
