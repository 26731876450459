import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ shortcuts, spacing }: Theme) {
  return createStyles({
    root: {
      width: 468,
    },
    logoWrapper: {
      textAlign: 'center',
      marginBottom: spacing(8),
    },
    forgotPasswordLink: {
      ...shortcuts.link.primary,
      marginBottom: -spacing(),
    },
    submitButton: {
      marginLeft: spacing(3),
    },
  });
}
