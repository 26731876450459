import { createStyles, Theme } from '@material-ui/core/styles';

import Background from './assets/portal-login-backgrond.png';

export default function styles({ spacing, radius }: Theme) {
  return createStyles({
    root: {
      height: '100vh',
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
    },
    moduleLoading: {
      paddingTop: 100,
      paddingBottom: 100,
      minHeight: 300,
    },
    container: {
      padding: spacing(10, 8, 8, 8),
      borderRadius: radius.containers.secondary,
    },
  });
}
