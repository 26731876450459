import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      padding: spacing(5, 0, 7),
    },
    logo: {
      marginRight: spacing(4),
    },
    disabledLogo: {
      pointerEvents: 'none',
    },
    addIcon: {
      paddingRight: spacing(2),
    },
    moduleName: {
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.2,
    },
    icon: {
      margin: spacing(0, 1, 0, 2),
      fontSize: 20,
    },
    btn: {
      marginLeft: spacing(2),
      height: 'auto',
    },
  });
}
