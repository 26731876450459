import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { LocationDescriptorObject } from 'history';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ArrowBackMobileIcon from '@material-ui/icons/ArrowBackIos';
import withWidth, { WithWidth, isWidthDown } from '@material-ui/core/withWidth';
import cx from 'classnames';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';
import Button, { ButtonProps } from '@shared/components/Button';
import history from '@shared/utils/history';

import styles from './BackButton.styles';

export interface BackButtonProps
  extends Omit<ButtonProps, 'classes'>,
    WithStyles<typeof styles>,
    WithWidth {
  link?: string;
  location?: LocationDescriptorObject;
  text?: React.ReactNode;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const {
    link,
    location,
    text,
    width,
    classes,
    variant = 'contained',
    color = 'secondary',
    onClick,
  } = props;
  const isMobile = isWidthDown(BREAKPOINT.mobile, width);

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);

      return;
    }

    if (link) {
      history.push(link);

      return;
    }

    if (location) {
      history.push(location);

      return;
    }

    history.goBack();
  };
  const {
    root: rootClass,
    label: labelClass,
    labelVariantText: labelVariantTextClass,
    iconWrapper: iconWrapperClass,
    iconWrapperVariantText: iconWrapperVariantTextClass,
    ...otherClasses
  } = classes;

  return (
    <Button
      size="small"
      variant={variant}
      color={color}
      classes={{
        root: rootClass,
        label: cx(labelClass, { [labelVariantTextClass]: variant === 'text' }),
        ...otherClasses,
      }}
      onClick={handleClick}
    >
      <span className={cx(iconWrapperClass, { [iconWrapperVariantTextClass]: variant === 'text' })}>
        {isMobile ? <ArrowBackMobileIcon /> : <ChevronLeft />}
      </span>
      {text}
    </Button>
  );
};

BackButton.defaultProps = {
  text: 'Back',
};

export default withStyles(styles)(withWidth()(BackButton));
