import { UserStatus } from '@Portal/shared/constants/user';

export interface DentistDTO {
  id: number;
  fullName: string;
  email: string;
  profileStatus?: UserStatus;
  specialty: string;
  profileImage?: string;
  profileImageUrl?: string;
}

export default class Dentist {
  id: number;
  fullName: string;
  email: string;
  profileStatus: UserStatus;
  specialty: string;
  profileImage?: string;
  profileImageUrl?: string;

  constructor(dto: Partial<DentistDTO>) {
    this.update(dto);
  }

  get asJson(): DentistDTO {
    return {
      id: this.id,
      fullName: this.fullName,
      email: this.email,
      profileStatus: this.profileStatus,
      specialty: this.specialty,
      profileImage: this.profileImage,
      profileImageUrl: this.profileImageUrl,
    };
  }

  update(newData: Partial<DentistDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
