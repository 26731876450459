export enum UserStatus {
  pending = 1,
  active = 2,
  blocked = 3,
}

export const USER_STATUS_LABELS = {
  [UserStatus.pending]: 'Pending',
  [UserStatus.active]: 'Active',
  [UserStatus.blocked]: 'Blocked',
};

export const USER_FIELDS = {
  email: 'email',
  username: 'username',
  fullName: 'fullName',
  id: 'id',
  password: 'password',
  newPassword: 'newPassword',
  newPasswordConfirm: 'newPasswordConfirm',
};
