import { USER_FIELDS } from '@Portal/shared/constants/user';
import Form from '@core/forms/base';

export default class LoginForm extends Form {
  setup() {
    return {
      fields: [
        {
          name: USER_FIELDS.username,
          type: 'text',
          rules: 'required|email',
        },
        {
          name: USER_FIELDS.password,
          type: 'password',
          rules: 'required',
        },
      ],
    };
  }
}
