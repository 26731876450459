import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './LogoBig.styles';

interface LogoBigIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const LogoBigIcon: React.FC<LogoBigIconProps> = (props) => {
  const color = '#43DFA7';

  return (
    <SvgIcon width="75" height="40" viewBox="0 0 75 40" {...props}>
      <path
        d="M5.09145 36.1777C5.09145 37.4805 4.10532 38.292 2.54037 38.292H1.36129V39.9044H0V34.0528H2.54037C4.11603 34.0528 5.09145 34.8643 5.09145 36.1777ZM3.71944 36.1777C3.71944 35.5371 3.3014 35.1526 2.46533 35.1526H1.36129V37.1815H2.46533C3.3014 37.1815 3.71944 36.8077 3.71944 36.1777Z"
        fill="currentColor"
      />
      <path
        d="M5.77783 36.9782C5.77783 35.2377 7.13913 33.9563 8.99348 33.9563C10.8371 33.9563 12.2091 35.2377 12.2091 36.9782C12.2091 38.7294 10.8371 40.0001 8.99348 40.0001C7.13913 40.0001 5.77783 38.7188 5.77783 36.9782ZM10.8264 36.9782C10.8264 35.8677 10.0332 35.1096 8.99348 35.1096C7.94304 35.1096 7.16056 35.8677 7.16056 36.9782C7.16056 38.0888 7.95376 38.8469 8.99348 38.8469C10.0439 38.8469 10.8264 38.0888 10.8264 36.9782Z"
        fill="currentColor"
      />
      <path
        d="M12.8623 36.9782C12.8623 35.2164 14.2236 33.9563 16.0458 33.9563C17.0641 33.9563 17.9109 34.3194 18.4575 34.9921L17.5893 35.793C17.1927 35.3445 16.6996 35.1096 16.1101 35.1096C15.0061 35.1096 14.2343 35.8784 14.2343 36.9782C14.2343 38.0781 15.0168 38.8469 16.1101 38.8469C16.6996 38.8469 17.1927 38.612 17.5893 38.1528L18.4575 38.9537C17.9002 39.6264 17.0534 40.0001 16.0351 40.0001C14.2236 40.0001 12.8623 38.7401 12.8623 36.9782Z"
        fill="currentColor"
      />
      <path
        d="M21.5232 37.6086L20.73 38.4308V39.9151H19.3794V34.0635H20.73V36.7971L23.3346 34.0635H24.846L22.4128 36.669L24.9854 39.9151H23.399L21.5232 37.6086Z"
        fill="currentColor"
      />
      <path
        d="M30.1415 38.8152V39.9043H25.5967V34.0528H30.0343V35.1419H26.9473V36.4126H29.6698V37.4698H26.9473V38.8259H30.1415V38.8152Z"
        fill="currentColor"
      />
      <path
        d="M32.4349 35.1526H30.5591V34.0528H35.6827V35.1526H33.8069V39.9043H32.4456V35.1526H32.4349Z"
        fill="currentColor"
      />
      <path
        d="M38.9736 34.0528H41.4497C43.3362 34.0528 44.6225 35.238 44.6225 36.9786C44.6225 38.7191 43.3362 39.9043 41.4497 39.9043H38.9736V34.0528ZM41.3961 39.1782C42.8431 39.1782 43.7757 38.292 43.7757 36.9786C43.7757 35.6652 42.8431 34.7789 41.3961 34.7789H39.8097V39.1782H41.3961Z"
        fill={color}
      />
      <path
        d="M50.164 39.1782V39.9043H45.8979V34.0528H50.0461V34.7789H46.7447V36.5728H49.6924V37.2882H46.7447V39.1782H50.164Z"
        fill={color}
      />
      <path
        d="M56.5745 34.0528V39.9043H55.8884L52.3619 35.537V39.9043H51.5259V34.0528H52.2119L55.7384 38.4201V34.0528H56.5745Z"
        fill={color}
      />
      <path
        d="M59.5107 34.7789H57.4956V34.0528H62.3512V34.7789H60.3361V39.9043H59.5V34.7789H59.5107Z"
        fill={color}
      />
      <path d="M63.2622 34.0528H64.0983V39.9043H63.2622V34.0528Z" fill={color} />
      <path
        d="M65.3311 39.2314L65.6419 38.58C66.0814 38.9751 66.821 39.2527 67.582 39.2527C68.6003 39.2527 69.0291 38.8576 69.0291 38.3451C69.0291 36.9142 65.4704 37.8219 65.4704 35.6435C65.4704 34.7359 66.1778 33.9671 67.7214 33.9671C68.4074 33.9671 69.1255 34.1486 69.6079 34.4903L69.3292 35.163C68.8147 34.832 68.2359 34.6825 67.7214 34.6825C66.7245 34.6825 66.2958 35.099 66.2958 35.6115C66.2958 37.0424 69.8544 36.1454 69.8544 38.2917C69.8544 39.1887 69.1362 39.9575 67.582 39.9575C66.6816 39.9681 65.8027 39.6692 65.3311 39.2314Z"
        fill={color}
      />
      <path
        d="M72.1597 34.7789H70.1445V34.0528H75.0002V34.7789H72.985V39.9043H72.149V34.7789H72.1597Z"
        fill={color}
      />
      <path
        d="M45.6085 2.32781V13.3689C45.63 13.465 45.6514 13.5611 45.6728 13.6572H42.5001C42.4786 12.8777 42.1999 12.1409 41.8033 11.4042C41.0423 10.0053 39.8096 10.0587 38.9628 10.6567C38.116 11.2547 35.2756 12.5894 35.2756 12.5894L37.5479 10.9236C36.7333 11.2226 35.6186 9.71702 34.1286 10.4858C33.1854 10.977 32.6066 12.2904 32.6173 13.6465H29.4445C29.5517 13.0699 29.7125 12.5253 29.9376 12.0021C30.3663 11.0091 30.9451 10.1335 31.6955 9.39668C32.4458 8.6599 33.3247 8.08328 34.3216 7.65616C35.3184 7.22904 36.401 7.02615 37.5587 7.02615C38.7163 7.02615 39.7882 7.23972 40.7958 7.67752C41.2567 7.86972 41.6747 8.10464 42.082 8.36091V0.694073C40.635 0.245595 39.0915 0 37.4944 0C29.005 0 22.1235 6.85531 22.1235 15.3123C22.1235 20.7688 24.9855 25.5526 29.2944 28.2648V16.7005H33.7856C34.4824 17.9498 34.5681 18.8254 35.2005 20.2776C35.9616 22.0395 36.4653 20.8756 36.4653 20.8756C36.5082 20.7902 36.8727 20.1281 36.9263 19.5622C36.9477 19.3593 37.0227 19.1778 37.1192 19.0069C37.3014 18.6866 37.7838 18.7079 37.9338 19.0496C38.2983 19.8825 38.0839 21.1639 38.8556 21.2493C39.8311 21.3561 40.3884 19.3166 40.4956 18.8468C40.5814 18.4517 40.6671 17.8964 41.3639 16.7005H45.705C45.5978 17.3198 45.4263 17.9178 45.1798 18.4837C44.7403 19.4768 44.1615 20.3524 43.4219 21.0891C42.6823 21.8259 41.814 22.4132 40.8065 22.8403C39.8096 23.2781 38.727 23.4917 37.5694 23.4917C37.1192 23.4917 36.6797 23.4383 36.2295 23.3422C35.7793 23.2461 35.3506 23.1073 34.9433 22.9471C34.5252 22.787 34.1394 22.5947 33.7749 22.3919C33.4105 22.1783 33.0996 21.9541 32.8317 21.7085V29.9092C34.3001 30.379 35.8758 30.6353 37.5051 30.6353C45.9944 30.6353 52.8759 23.78 52.8759 15.323C52.8759 9.83448 49.9711 5.02936 45.6085 2.32781Z"
        fill="url(#paint0_radial)"
      />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.8978 8.33439) scale(14.9155 14.8587)"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor="#454E7E" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default withStyles(styles)(LogoBigIcon);
