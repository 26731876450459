import React from 'react';
import MaskedInput from 'react-text-mask';
import { TextFieldMask, textFieldMask } from './maskFormats';

// Keep this comment:
// for use (space) on placeholderChar - pass {'\u2000'}
// because you can't use ' ' - should be different than chars in mask 'prop'

export interface TextMaskInputProps {
  maskType: TextFieldMask;
  showMask?: boolean;
  inputRef: (instance: any) => void; // BaseTextFieldProps['inputRef'];
  placeholderChar?: string;
}

export const TextMaskInput: React.FC<TextMaskInputProps> = (props) => {
  const { inputRef, maskType, showMask = false, placeholderChar = '\u2000', ...rest } = props;

  return (
    <MaskedInput
      {...rest}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={textFieldMask[maskType].mask}
      pipe={textFieldMask[maskType].pipe}
      showMask={showMask}
      placeholderChar={placeholderChar}
    />
  );
};
