import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './ExpiredLink.styles';

interface ExpiredLinkIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const ExpiredLinkIcon: React.FC<ExpiredLinkIconProps> = ({ classes }) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" classes={{ root: classes.root }}>
      <g clipPath="url(#clip0)">
        <path
          d="M32.2311 5.76353L28.6221 9.37256L30.6275 11.378L34.2365 7.76893L32.2311 5.76353Z"
          fill="#E0E0E0"
        />
        <path d="M28.075 2.40723H25.2383V7.5123H28.075V2.40723Z" fill="#E0E0E0" />
        <path d="M37.6339 11.9648H32.5278V14.8009H37.6339V11.9648Z" fill="#E0E0E0" />
        <path
          d="M8.96916 29.025L5.35791 32.6357L7.36359 34.6417L10.9748 31.031L8.96916 29.025Z"
          fill="#E0E0E0"
        />
        <path d="M7.10996 25.6431H2.00488V28.4792H7.10996V25.6431Z" fill="#E0E0E0" />
        <path d="M14.4002 32.9307H11.5635V38.0368H14.4002V32.9307Z" fill="#E0E0E0" />
        <path
          d="M16.5628 9.58582C16.6723 9.58582 16.8317 9.61363 16.9632 9.74527L22.2055 14.9876L25.8154 11.3778L20.573 6.13543C19.4704 5.03277 18.016 4.4809 16.5628 4.4809C15.109 4.4809 13.6546 5.0323 12.552 6.13496L11.1475 7.53949L4.35711 0.748398C3.36055 -0.248164 1.74445 -0.248164 0.747891 0.748398C-0.249297 1.74559 -0.249297 3.36152 0.747891 4.35824L7.53875 11.1491L5.73336 12.954C3.5268 15.1606 3.5268 18.7704 5.73273 20.9764L10.9751 26.2187L14.5843 22.6089L9.34195 17.3665C9.21094 17.2349 9.18312 17.0761 9.18312 16.9655C9.18312 16.855 9.21094 16.6955 9.34258 16.5634L11.1475 14.7585L12.7805 16.3915C13.7771 17.3887 15.3926 17.3887 16.3898 16.3915C17.387 15.3943 17.387 13.7789 16.3898 12.7823L14.7567 11.1493L16.1612 9.74535C16.2928 9.61363 16.4522 9.58582 16.5628 9.58582Z"
          fill="#E0E0E0"
        />
        <path
          d="M39.2528 35.6435L32.4336 28.8249L33.8375 27.421C36.0441 25.2144 36.0441 21.6052 33.8381 19.3986L28.6236 14.1841L25.0138 17.7939L30.2276 23.0079C30.4415 23.2218 30.4415 23.5967 30.2276 23.8106L28.8237 25.2145L27.2195 23.6103C26.2223 22.6131 24.6069 22.6131 23.6103 23.6103C22.6131 24.6069 22.6131 26.2224 23.6103 27.2196L25.2145 28.8237L23.4084 30.6297C23.2768 30.7613 23.1175 30.7892 23.0074 30.7892C22.898 30.7892 22.7385 30.7613 22.607 30.6297L17.393 25.4152L13.7832 29.025L18.9972 34.2396C20.0998 35.3422 21.5542 35.8941 23.0074 35.8941C24.4613 35.8941 25.9156 35.3422 27.0183 34.2396L28.8243 32.4335L35.643 39.2522C36.6402 40.2494 38.2563 40.2494 39.2528 39.2522C40.2494 38.2556 40.2494 36.6396 39.2528 35.6435Z"
          fill="#E0E0E0"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default withStyles(styles)(ExpiredLinkIcon);
