import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import styles from './Ellipsis.styles';

export interface EllipsisProps extends WithStyles<typeof styles> {
  text: string | number | React.ReactNode;
  tooltipText?: string;
  component?: React.ComponentType<any>;
  componentProps?: any;
  width?: string | number;
  maxWidth?: string | number;
  withTooltip?: boolean;
}

const Ellipsis: React.FC<EllipsisProps> = ({
  classes,
  width,
  maxWidth,
  text,
  tooltipText,
  withTooltip,
  component: Component,
  componentProps,
}) => {
  const contentStyles = Component ? { maxWidth: '100%' } : { width, maxWidth };

  const content = (
    <>
      {withTooltip ? (
        <Tooltip placement="top-start" title={tooltipText || String(text) || ''}>
          <div style={contentStyles} className={classes.root}>
            {text}
          </div>
        </Tooltip>
      ) : (
        <div style={contentStyles} className={classes.root}>
          {text}
        </div>
      )}
    </>
  );

  if (Component) {
    return (
      <Component style={{ width, maxWidth }} {...componentProps}>
        {content}
      </Component>
    );
  }

  return content;
};

Ellipsis.defaultProps = {
  withTooltip: true,
};

export default withStyles(styles)(Ellipsis);
