import { createStyles, Theme, fade } from '@material-ui/core/styles';

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    rootAbsolute: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 3,
      backgroundColor: fade('#fff', 0.5),
    },
    none: {
      margin: 0,
    },
    small: {
      margin: spacing(2),
    },
    normal: {
      margin: spacing(4),
    },
    big: {
      margin: spacing(6),
    },
    svg: {},
  });
