import { createStyles } from '@material-ui/core';

export default () =>
  createStyles({
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 14,
    },
  });
