import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { RouteComponentProps } from 'react-router-dom';

import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';
import ForgotPasswordForm from './forms/forgot-password';
import TextField from '@shared/components/TextField';
import Form from '@shared/components/Form';
import Field from '@shared/components/Form/components/FormUnit';
import AuthHeading from '@shared/components/AuthHeading';
import { NotificationType, showNotification } from '@shared/components/Notification';
import AuthFormFooter from '@Portal/modules/Public/components/AuthFormFooter';
import BackButton from '@shared/components/BackButton';
import Button from '@shared/components/Button';
import { USER_FIELDS } from '@Portal/shared/constants/user';
import ROUTES from '@Portal/shared/constants/routes';

import styles from './ForgotPassword.styles';

export interface ForgotPasswordProps extends WithStyles<typeof styles>, RouteComponentProps {}

@observer
class ForgotPassword extends React.Component<ForgotPasswordProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private form: BaseForm = new ForgotPasswordForm();
  @observable private submitting = false;

  private handleSubmit = async (data) => {
    this.submitting = true;

    const { history } = this.props;

    try {
      await this.authStore.resetPassword(data);

      showNotification(
        'We\'ve sent you password recovery instructions on the email. Please, check your inbox.',
        NotificationType.email
      );
      history.push(ROUTES.public.login);
    } catch (err) {
      throw err;
    } finally {
      this.submitting = false;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <BackButton classes={{ root: classes.backBtn }} />
        <AuthHeading
          heading="Forgot Password"
          subheading={
            <>
              We will send you a link to reset your password. <br /> Please enter your email address
              below.{' '}
            </>
          }
        />
        <Form withControls={false} formInstance={this.form} onSubmit={this.handleSubmit}>
          <Field
            label="Email"
            component={
              <TextField placeholder="Enter email" field={this.form.$(USER_FIELDS.email)} />
            }
          />
          <AuthFormFooter>
            <Button
              type="submit"
              text="Send me a link"
              classes={{ root: classes.submitButton }}
              loading={this.submitting}
            />
          </AuthFormFooter>
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
