import { injectable } from 'inversify';
import Axios from 'axios';

import { Id } from '@shared/types/common';
import Clinic, { ClinicDTO } from '@Portal/shared/models/clinic';
import Statistics, { StatisticsDTO } from '@Portal/shared/models/statistics';
import Dentist, { DentistDTO } from '@Portal/shared/models/dentist';
import Receptionist, { ReceptionistDTO } from '@Portal/shared/models/receptionist';
import { UserRole } from '@Clinic/shared/models/system-user';
import { REQUEST_PARAMS } from '@shared/constants/services';
import { ListRequestParams, ListResponse } from '@shared/types/services';
import { DENTIST_FIELDS } from '@Portal/shared/constants/dentist';
import { RECEPTIONIST_FIELDS } from '@Portal/shared/constants/receptionist';
import { CLINIC_FIELDS } from '@Portal/shared/constants/clinic';

enum UserRoleFilter {
  all = 'all',
  dentist = 'dentist',
  receptionist = 'receptionist',
}

@injectable()
export default class ClinicsService {
  static diToken = Symbol('clinics-service');

  private urlPrefix = '/clinics';

  createUser<T>(clinicId: Id, data: T, role: UserRole.dentist | UserRole.receptionist) {
    const url = `${this.urlPrefix}/${clinicId}/users`;

    return Axios.post(url, { ...data, roles: [role] });
  }

  async getClinic(id: Id) {
    const { data } = await Axios.get<ClinicDTO>(this.getURL(id));

    return new Clinic(data);
  }

  editClinic(id: Id, data: ClinicDTO) {
    const url = this.getURL(id);

    return Axios.put(url, data);
  }

  createClinic(data: Partial<ClinicDTO>) {
    const url = this.urlPrefix;

    return Axios.post(url, data);
  }

  async getReceptionist(clinicId: Id, userId: Id) {
    const { data } = await Axios.get<DentistDTO>(this.getURL(`${clinicId}/users/${userId}`));

    return new Receptionist(data);
  }

  async getDentist(clinicId: Id, userId: Id) {
    const { data } = await Axios.get<DentistDTO>(this.getURL(`${clinicId}/users/${userId}`));

    return new Dentist(data);
  }

  resendUserInvite(clinicId: Id, userId: Id) {
    const url = `${this.urlPrefix}/${clinicId}/users/${userId}/activation/resend`;

    return Axios.post(url);
  }

  editUser(clinicId: Id, id: Id, data: DentistDTO | ReceptionistDTO) {
    const url = this.getURL(`${clinicId}/users/${id}`);

    return Axios.put(url, data);
  }

  blockUser(clinicId: Id, id: Id) {
    const url = this.getURL(`${clinicId}/users/${id}/block`);

    return Axios.post(url);
  }

  unblockUser(clinicId: Id, id: Id) {
    const url = this.getURL(`${clinicId}/users/${id}/unblock`);

    return Axios.post(url);
  }

  getDentistsListQuery({ pagination, sorting }: ListRequestParams) {
    return `
      {
        users(
          ${REQUEST_PARAMS.pagination.fields.page}: ${pagination.page}, 
          ${REQUEST_PARAMS.pagination.fields.pageSize}: ${pagination.pageSize}, 
          ${REQUEST_PARAMS.sorting.name}: { ${REQUEST_PARAMS.sorting.fields.type}: ${sorting?.type}, ${REQUEST_PARAMS.sorting.fields.field}: ${sorting?.field} }, 
          role: ${UserRoleFilter.dentist}
        ) {
          ${REQUEST_PARAMS.items} {
            ${DENTIST_FIELDS.id},
            ${DENTIST_FIELDS.fullName}, 
            ${DENTIST_FIELDS.email},
            ${DENTIST_FIELDS.specialty},
            ${DENTIST_FIELDS.profileStatus}
          }
          ${REQUEST_PARAMS.pageInfo.name} {
            ${REQUEST_PARAMS.pageInfo.fields.totalCount},
            ${REQUEST_PARAMS.pageInfo.fields.hasPreviousPage},
            ${REQUEST_PARAMS.pageInfo.fields.hasNextPage}
          }
        }
      }
    `;
  }

  async getDentists(clinicId: Id, params: ListRequestParams) {
    const { data } = await Axios.get<{ users: ListResponse<Dentist> }>(
      this.getURL(`${clinicId}/users`),
      { params: { query: this.getDentistsListQuery(params) } }
    );

    const {
      users: { items, pageInfo },
    } = data;

    return {
      pageInfo,
      items: items.map((dto) => new Dentist(dto)),
    };
  }

  getClinicsListQuery({ pagination, sorting }: ListRequestParams) {
    return `
      {
        clinics(
          ${REQUEST_PARAMS.pagination.fields.page}: ${pagination.page}, 
          ${REQUEST_PARAMS.pagination.fields.pageSize}: ${pagination.pageSize}, 
          ${REQUEST_PARAMS.sorting.name}: { ${REQUEST_PARAMS.sorting.fields.type}: ${sorting?.type}, ${REQUEST_PARAMS.sorting.fields.field}: ${sorting?.field} }, 
        ) {
          ${REQUEST_PARAMS.items} {
            ${CLINIC_FIELDS.id},
            ${CLINIC_FIELDS.name}, 
            ${CLINIC_FIELDS.userInterfaceConfiguration.fields.logoUrl},
            ${CLINIC_FIELDS.phoneNumber},
            ${CLINIC_FIELDS.domainName},
          }
          ${REQUEST_PARAMS.pageInfo.name} {
            ${REQUEST_PARAMS.pageInfo.fields.totalCount},
            ${REQUEST_PARAMS.pageInfo.fields.hasPreviousPage},
            ${REQUEST_PARAMS.pageInfo.fields.hasNextPage}
          }
        }
      }
    `;
  }

  async getClinics(params: ListRequestParams) {
    const { data } = await Axios.get<{ clinics: ListResponse<Clinic> }>(this.urlPrefix, {
      params: { query: this.getClinicsListQuery(params) },
    });

    const {
      clinics: { items, pageInfo },
    } = data;

    return {
      pageInfo,
      items: items.map((dto) => new Clinic(dto)),
    };
  }

  getReceptionistsListQuery({ pagination, sorting }: ListRequestParams) {
    return `
      {
        users(
          ${REQUEST_PARAMS.pagination.fields.page}: ${pagination.page}, 
          ${REQUEST_PARAMS.pagination.fields.pageSize}: ${pagination.pageSize}, 
          ${REQUEST_PARAMS.sorting.name}: { ${REQUEST_PARAMS.sorting.fields.type}: ${sorting?.type}, ${REQUEST_PARAMS.sorting.fields.field}: ${sorting?.field} }, 
          role: ${UserRoleFilter.receptionist}
        ) {
          ${REQUEST_PARAMS.items} {
            ${RECEPTIONIST_FIELDS.id},
            ${RECEPTIONIST_FIELDS.fullName}, 
            ${RECEPTIONIST_FIELDS.email},
            ${RECEPTIONIST_FIELDS.profileStatus},
            ${RECEPTIONIST_FIELDS.sendNotifications}
          }
          ${REQUEST_PARAMS.pageInfo.name} {
            ${REQUEST_PARAMS.pageInfo.fields.totalCount},
            ${REQUEST_PARAMS.pageInfo.fields.hasPreviousPage},
            ${REQUEST_PARAMS.pageInfo.fields.hasNextPage}
          }
        }
      }
    `;
  }

  async getReceptionists(clinicId: Id, params: ListRequestParams) {
    const { data } = await Axios.get<{ users: ListResponse<Receptionist> }>(
      this.getURL(`${clinicId}/users`),
      { params: { query: this.getReceptionistsListQuery(params) } }
    );

    const {
      users: { items, pageInfo },
    } = data;

    return {
      pageInfo,
      items: items.map((dto) => new Receptionist(dto)),
    };
  }

  async getStatistics(clinicId: Id, date: string) {
    const params = date ? { statisticsPerDate: date } : {};
    const { data } = await Axios.get<StatisticsDTO>(this.getURL(`${clinicId}/statistics`), {
      params,
    });

    return new Statistics(data);
  }

  async uploadFile(file: File): Promise<Dentist['profileImage']> {
    const formData = new FormData();

    formData.append('File', file);

    const { data } = await Axios.put('/images', formData);

    return data;
  }

  private getURL(url: string | number) {
    return `${this.urlPrefix}/${url}`;
  }
}
