import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core/styles';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';

import Flex from '@shared/components/Flex';
import Button, { ButtonProps } from '@shared/components/Button';
import Logo from '@shared/components/Logo';
import BackButton, { BackButtonProps } from '@shared/components/BackButton';
import AddIcon from '@shared/icons/Add/Add';

import styles from './Header.styles';

export interface HeaderConfig {
  moduleName?: string;
  defaultRoute: string;
  backButtonProps?: Partial<BackButtonProps>;
  createButtonProps?: Pick<ButtonProps, 'text' | 'onClick'>;
}

export interface HeaderProps extends WithStyles<typeof styles>, HeaderConfig {
  height: number;
  profileLink: string;
  onLogoutClick: () => any;
}

const Header: React.FC<HeaderProps> = ({
  classes,
  moduleName,
  height,
  defaultRoute,
  // profileLink,
  onLogoutClick,
  backButtonProps,
  createButtonProps,
}) => {
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.root}
      style={{ height }}
    >
      <Flex alignItems="center">
        <NavLink exact to={defaultRoute} activeClassName={classes.disabledLogo}>
          <Logo classes={{ root: classes.logo }} />
        </NavLink>
        {createButtonProps && (
          <Button size="small" onClick={createButtonProps.onClick}>
            <AddIcon classes={{ root: classes.addIcon }} />
            {createButtonProps.text}
          </Button>
        )}
        {backButtonProps && <BackButton {...backButtonProps} />}
      </Flex>
      <h6 className={classes.moduleName}>{moduleName}</h6>
      <Flex alignItems="center">
        {/* <Link to={profileLink}>
          <Button className={classes.btn} variant="text">
            <AccountCircleIcon className={classes.icon} />
            Profile
          </Button>
        </Link> */}
        <Button className={classes.btn} variant="text" onClick={onLogoutClick}>
          <ExitToAppIcon className={classes.icon} />
          Log out
        </Button>
      </Flex>
    </Grid>
  );
};

export default withStyles(styles)(Header);
