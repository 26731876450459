import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      width: 75,
      height: 40,
    },
  });
}
