import { toJS } from 'mobx';

export enum StatisticsGroup {
  patientStatisticsModels = 'patientStatisticsModels',
  statisticsRequests = 'statisticsRequests',
  consultationsHeld = 'consultationsHeld',
}
export interface StatisticsDTO {
  [StatisticsGroup.patientStatisticsModels]: {
    total: number;
    perMonth: number;
  };
  [StatisticsGroup.statisticsRequests]: {
    dentalProblem: number;
    smileImprovements: number;
  };
  [StatisticsGroup.consultationsHeld]: {
    virtualConsultation: {
      count: number;
      fee: number;
    };
  };
}
export default class Statistics {
  [StatisticsGroup.patientStatisticsModels]: {
    total: number;
    perMonth: number;
  };
  [StatisticsGroup.statisticsRequests]: {
    dentalProblem: number;
    smileImprovements: number;
    virtualConsultation: number;
  };
  [StatisticsGroup.consultationsHeld]: {
    virtualConsultation: {
      count: number;
      fee: number;
    };
  };

  constructor(dto: StatisticsDTO) {
    this.update(dto);
  }

  get asJson(): StatisticsDTO {
    return {
      [StatisticsGroup.patientStatisticsModels]: toJS(this.patientStatisticsModels),
      [StatisticsGroup.statisticsRequests]: toJS(this.statisticsRequests),
      [StatisticsGroup.consultationsHeld]: toJS(this.consultationsHeld),
    };
  }

  update(newData: StatisticsDTO) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
