import { createStyles, Theme } from '@material-ui/core';

export default function styles({ spacing, palette: { colors, primary }, shortcuts }: Theme) {
  return createStyles({
    root: {
      position: 'relative',

      '&:hover': {
        borderColor: colors.grey2,
      },
    },
    rootFullWidth: {
      width: '100%',
    },
    medium: {},
    small: {
      height: 36,
      fontSize: 14,
    },
    labelRoot: {
      margin: spacing(4, 0, 1),
    },
    showPasswordBtn: {
      padding: spacing(2),

      '& svg': {
        fontSize: 24,
        color: colors.grey,
      },
    },
    errorText: {
      ...shortcuts.errorText,
      bottom: -14,
    },
    passwordRequirements: {
      position: 'absolute',
      left: 350,
    },
    showPasswordIcon: {
      fill: primary.main,
    },
  });
}
