import * as csstype from 'csstype';

import { ThemeSettings } from '../theme.types';
import { colors, opacity } from '../constants';

export const getShortcuts = (settings: ThemeSettings) => {
  const { mainColor } = settings;

  return {
    disabled: {
      opacity: opacity.disabled,
      pointerEvents: 'none' as csstype.PointerEventsProperty,
    },
    errorText: {
      fontSize: 11,
      fontWeight: 400,
      width: '100%',
      color: colors.error,
      position: 'absolute' as csstype.PositionProperty,
    },
    link: {
      primary: {
        fontSize: 16,
        color: mainColor,
        fontWeight: 700,
        cursor: 'pointer',

        '&:hover': {
          opacity: 0.9,
        },
      },
      secondary: {
        fontSize: 14,
        color: mainColor,
        fontWeight: 400,
        cursor: 'pointer',
      },
    },
  };
};
