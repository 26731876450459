const ROUTES = {
  public: {
    login: '/login',
    forgotPassword: '/forgot-password',
    newPassword: '/new-password',
  },
  private: {
    profile: '/profile',
    clinics: {
      path: '/clinics',
      children: {
        dentists: '/dentists',
        generalInfo: '/general-info',
        receptionists: '/receptionists',
        customization: '/customization',
        statistics: '/statistics',
      },
    },
  },
  initial: '/',
};

export default ROUTES;
