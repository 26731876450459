import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import cx from 'classnames';

import { validationRegExpr } from '@core/forms/plugins';
import Flex from '@shared/components/Flex';

import styles from './PasswordRequirements.styles';

export interface PasswordRequirementsProps extends WithStyles<typeof styles> {
  value: string;
  heading?: React.ReactNode;
  requirements?: Array<{ label: string; validator: RegExp }>;
}

const defaultRequirements = [
  { validator: validationRegExpr.minimumLength, label: '8 characters long' },
  { validator: validationRegExpr.oneUpperCase, label: 'At least one uppercase letter' },
  { validator: validationRegExpr.oneLowerCase, label: 'At least one lowercase letter' },
  { validator: validationRegExpr.specialChar, label: 'At least one symbol' },
];

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  classes,
  heading,
  requirements,
  value,
}) => {
  return (
    <Paper elevation={2} className={classes.root}>
      <h6 className={classes.heading}>{heading}</h6>
      <div className={classes.requirements}>
        {requirements?.map(({ label, validator }, i) => (
          <Flex key={label} alignItems="center">
            <span
              className={cx(
                classes.indicator,
                validator.test(value) ? classes.indicatorSuccess : classes.indicatorError
              )}
            />
            {label}
          </Flex>
        ))}
      </div>
    </Paper>
  );
};

PasswordRequirements.defaultProps = {
  requirements: defaultRequirements,
  heading: 'Password must:',
};

export default withStyles(styles)(PasswordRequirements);
