import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ breakpoints, spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      width: 256,
    },
    heading: {
      color: colors.greyDark,
      fontSize: 24,
      fontWeight: 700,
      margin: spacing(4, 0),
    },
    subheading: {
      fontSize: 16,
      marginBottom: spacing(6),
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      root: {
        width: 'auto',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      },
      body: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        flexGrow: 1,
      },
      icon: {
        fontSize: 80,
      },
      button: {
        width: '100%',
      },
    },
  });
}
