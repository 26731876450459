import { WorkingDay } from '@Portal/shared/models/clinic';

export const WORKING_DAY_LABELS = {
  [WorkingDay.Friday]: 'Fri',
  [WorkingDay.Monday]: 'Mon',
  [WorkingDay.Saturday]: 'Sat',
  [WorkingDay.Sunday]: 'Sun',
  [WorkingDay.Thursday]: 'Thu',
  [WorkingDay.Tuesday]: 'Tue',
  [WorkingDay.Wednesday]: 'Wed',
};

export const WEEK_DAYS_SORTED = [
  WorkingDay.Monday,
  WorkingDay.Tuesday,
  WorkingDay.Wednesday,
  WorkingDay.Thursday,
  WorkingDay.Friday,
  WorkingDay.Saturday,
  WorkingDay.Sunday,
];

export const CLINIC_FIELDS = {
  id: 'id',
  name: 'name',
  phoneNumber: 'phoneNumber',
  domainName: 'domainName',
  userInterfaceConfiguration: {
    name: 'userInterfaceConfiguration',
    fields: {
      logoUrl: 'logoUrl',
      color: 'color',
      font: 'font',
    },
  },
};
