import { injectable } from 'inversify';
import { observable, action, computed } from 'mobx';

import ClinicsService from '@Portal/shared/services/clinics';
import container from '@Portal/core/di-container';
import { Id } from '@shared/types/common';
import Clinic, { ClinicDTO } from '@Portal/shared/models/clinic';
import Receptionist, { ReceptionistDTO } from '@Portal/shared/models/receptionist';
import Dentist, { DentistDTO } from '@Portal/shared/models/dentist';
import { UserRole } from '@Clinic/shared/models/system-user';
import { ListRequestParams, PageInfo } from '@shared/types/services';
import { SortingType } from '@shared/constants/services';
import { DENTIST_FIELDS } from '@Portal/shared/constants/dentist';
import { RECEPTIONIST_FIELDS } from '@Portal/shared/constants/receptionist';
import { USER_FIELDS } from '@Portal/shared/constants/user';

@injectable()
export default class ClinicsStore {
  static diToken = Symbol('clinics-store');

  private service = container.get<ClinicsService>(ClinicsService.diToken);

  @observable private _dentists: Array<Dentist> = [];
  @observable private _receptionists: Array<Receptionist> = [];
  @observable private _clinics: Array<Clinic> = [];
  @observable private _dentistsPageInfo: PageInfo;
  @observable private _receptionistsPageInfo: PageInfo;
  @observable private _clinicsPageInfo: PageInfo;

  @observable error = {
    itemGet: false,
  };
  @observable loading = {
    list: false,
    item: false,
  };

  @computed get dentistsPageInfo() {
    return this._dentistsPageInfo;
  }

  @computed get receptionistsPageInfo() {
    return this._receptionistsPageInfo;
  }

  @computed get clinicsPageInfo() {
    return this._clinicsPageInfo;
  }

  @computed get dentists() {
    return this._dentists;
  }

  @computed get receptionists() {
    return this._receptionists;
  }

  @computed get clinics() {
    return this._clinics;
  }

  @action getClinic = async (id: Id) => {
    this.loading.item = true;
    this.error.itemGet = false;

    try {
      const clinic = await this.service.getClinic(id);

      return clinic;
    } catch {
      this.error.itemGet = true;
    } finally {
      this.loading.item = false;
    }
  };

  editClinic = async (id: Id, data: ClinicDTO) => {
    return this.service.editClinic(id, data);
  };

  createClinic = async (data: Partial<ClinicDTO>) => {
    return this.service.createClinic(data);
  };

  @action getDentist = async (clinicId: Id, id: Id) => {
    this.loading.item = true;
    this.error.itemGet = false;

    try {
      const dentist = await this.service.getDentist(clinicId, id);

      return dentist;
    } catch {
      this.error.itemGet = true;
    } finally {
      this.loading.item = false;
    }
  };

  @action getReceptionist = async (clinicId: Id, id: Id) => {
    this.loading.item = true;
    this.error.itemGet = false;

    try {
      const receptionist = await this.service.getReceptionist(clinicId, id);

      return receptionist;
    } catch {
      this.error.itemGet = true;
    } finally {
      this.loading.item = false;
    }
  };

  resendUserInvite = (clinicId: Id, userId: Id) => {
    return this.service.resendUserInvite(clinicId, userId);
  };

  editUser = async (
    clinicId: Id,
    id: Id,
    data: DentistDTO | ReceptionistDTO,
    userRole: UserRole
  ) => {
    await this.service.editUser(clinicId, id, data);

    const pagination = {
      pagination: { page: 0, pageSize: 10 },
      sorting: { field: USER_FIELDS.fullName, type: SortingType.asc },
    };

    if (userRole === UserRole.dentist) {
      this.getDentists(clinicId, pagination);
    }

    if (userRole === UserRole.receptionist) {
      this.getReceptionists(clinicId, pagination);
    }
  };

  blockUser = async (clinicId: Id, id: Id, userRole: UserRole.dentist | UserRole.receptionist) => {
    await this.service.blockUser(clinicId, id);

    const pagination = {
      pagination: { page: 0, pageSize: 10 },
      sorting: { field: USER_FIELDS.fullName, type: SortingType.asc },
    };

    if (userRole === UserRole.dentist) {
      this.getDentists(clinicId, pagination);
    }

    if (userRole === UserRole.receptionist) {
      this.getReceptionists(clinicId, pagination);
    }
  };

  unblockUser = async (clinicId: Id, id: Id, userRole: UserRole) => {
    await this.service.unblockUser(clinicId, id);

    const pagination = {
      pagination: { page: 0, pageSize: 10 },
      sorting: { field: USER_FIELDS.fullName, type: SortingType.asc },
    };

    if (userRole === UserRole.dentist) {
      this.getDentists(clinicId, pagination);
    }

    if (userRole === UserRole.receptionist) {
      this.getReceptionists(clinicId, pagination);
    }
  };

  @action getDentists = async (clinicId: Id, params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getDentists(clinicId, params);

      this._dentists = items;
      this._dentistsPageInfo = pageInfo;

      this.loading.list = false;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };

  createDentist = async (clinicId: Id, data: DentistDTO) => {
    const dentist = await this.service.createUser<DentistDTO>(clinicId, data, UserRole.dentist);

    const pagination = {
      pagination: { page: 0, pageSize: 10 },
      sorting: { field: DENTIST_FIELDS.fullName, type: SortingType.asc },
    };

    this.getDentists(clinicId, pagination);

    return dentist;
  };

  @action getReceptionists = async (clinicId: Id, params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getReceptionists(clinicId, params);

      this._receptionists = items;
      this._receptionistsPageInfo = pageInfo;

      this.loading.list = false;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };

  createReceptionist = async (clinicId: Id, data: ReceptionistDTO) => {
    const receptionist = await this.service.createUser<ReceptionistDTO>(
      clinicId,
      data,
      UserRole.receptionist
    );

    const pagination = {
      pagination: { page: 0, pageSize: 10 },
      sorting: { field: RECEPTIONIST_FIELDS.fullName, type: SortingType.asc },
    };

    this.getReceptionists(clinicId, pagination);

    return receptionist;
  };

  @action getStatistics = async (clinicId: Id, date: string) => {
    this.loading.item = true;
    this.error.itemGet = false;

    try {
      const data = await this.service.getStatistics(clinicId, date);

      return data;
    } catch {
      this.error.itemGet = true;
    } finally {
      this.loading.item = false;
    }
  };

  uploadFile(file: File) {
    return this.service.uploadFile(file);
  }

  @action getClinics = async (params: ListRequestParams) => {
    this.loading.list = true;

    try {
      const { items, pageInfo } = await this.service.getClinics(params);

      this._clinics = items;
      this._clinicsPageInfo = pageInfo;

      this.loading.list = false;

      return {
        items,
        pageInfo,
      };
    } finally {
      this.loading.list = false;
    }
  };
}
