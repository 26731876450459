export enum UserRole {
  dentist = 'Dentist',
  receptionist = 'Receptionist',
  patient = 'Patient',
}

export interface SystemUserDTO {
  sub: number;
  name: string;
  role: UserRole;
}

export default class SystemUser {
  id: number;
  name: string;
  role: UserRole;

  constructor(dto: SystemUserDTO) {
    this.update({ id: dto.sub, role: dto.role, name: dto.name });
  }

  get asJson(): SystemUserDTO {
    return {
      sub: this.id,
      name: this.name,
      role: this.role,
    };
  }

  update(newData: Partial<SystemUser>) {
    Object.assign(this, newData);

    return this.asJson;
  }
}
