import * as React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { WithStyles, withStyles } from '@material-ui/core';
import cx from 'classnames';

import styles from './Loading.styles';

export interface LoadingProps
  extends WithStyles<typeof styles>,
    Omit<CircularProgressProps, 'classes'> {
  margin?: 'none' | 'small' | 'normal' | 'big';
  absolute?: boolean;
}

const Loading: React.FunctionComponent<LoadingProps> = (props): JSX.Element => {
  const { classes, margin = 'none', absolute, ...otherProps } = props;

  return (
    <div className={cx(classes.root, classes[margin], { [classes.rootAbsolute]: absolute })}>
      <CircularProgress disableShrink size={40} classes={{ svg: classes.svg }} {...otherProps} />
    </div>
  );
};

export default withStyles(styles)(Loading);
