import { UserStatus } from '@Portal/shared/constants/user';

export interface ReceptionistDTO {
  id: number;
  fullName: string;
  email: string;
  profileStatus: UserStatus;
  sendNotifications: boolean;
}

export default class Receptionist {
  id: number;
  fullName: string;
  email: string;
  profileStatus: UserStatus;
  sendNotifications: boolean;

  constructor(dto: Partial<ReceptionistDTO>) {
    this.update(dto);
  }

  get asJson(): ReceptionistDTO {
    return {
      id: this.id,
      fullName: this.fullName,
      email: this.email,
      profileStatus: this.profileStatus,
      sendNotifications: this.sendNotifications,
    };
  }

  update(newData: Partial<ReceptionistDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
