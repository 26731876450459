import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './LogoSmall.styles';

interface LogoSmallIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const LogoSmallIcon: React.FC<LogoSmallIconProps> = (props) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M10.6915 1.06378V6.10945C10.7013 6.15336 10.711 6.19728 10.7208 6.2412H9.2764C9.26664 5.88498 9.13977 5.54827 8.95922 5.21157C8.61275 4.57232 8.05158 4.59672 7.66608 4.86999C7.28058 5.14326 5.98745 5.75322 5.98745 5.75322L7.02196 4.99198C6.6511 5.12862 6.1436 4.44057 5.46532 4.79191C5.0359 5.01638 4.77239 5.61659 4.77727 6.23632H3.33287C3.38166 5.97281 3.45486 5.72395 3.55734 5.48484C3.75253 5.03102 4.01603 4.63088 4.35761 4.29418C4.6992 3.95748 5.09934 3.69397 5.55315 3.49878C6.00697 3.30359 6.49983 3.21087 7.02684 3.21087C7.55385 3.21087 8.04183 3.30847 8.50052 3.50854C8.71035 3.59637 8.90066 3.70373 9.08609 3.82084V0.317184C8.42733 0.112234 7.72464 0 6.99756 0C3.1328 0 0 3.1328 0 6.99756C0 9.49111 1.30289 11.6772 3.26455 12.9167V7.63193H5.30917C5.62635 8.20286 5.66539 8.603 5.95329 9.26664C6.29976 10.0718 6.5291 9.53991 6.5291 9.53991C6.54862 9.50087 6.71453 9.19833 6.73893 8.9397C6.74869 8.84698 6.78285 8.76403 6.82677 8.68595C6.90972 8.53956 7.12931 8.54932 7.19763 8.70547C7.36354 9.08609 7.26594 9.67166 7.61729 9.7107C8.06134 9.7595 8.31509 8.82747 8.36389 8.61276C8.40293 8.43221 8.44197 8.17846 8.75915 7.63193H10.7354C10.6866 7.91495 10.6086 8.18822 10.4963 8.44685C10.2963 8.90066 10.0328 9.3008 9.69606 9.6375C9.35936 9.97421 8.9641 10.2426 8.5054 10.4378C8.05158 10.6379 7.55873 10.7354 7.03172 10.7354C6.82677 10.7354 6.6267 10.7111 6.42175 10.6671C6.2168 10.6232 6.02161 10.5598 5.83618 10.4866C5.64587 10.4134 5.4702 10.3255 5.30429 10.2328C5.13838 10.1352 4.99686 10.0328 4.87487 9.92053V13.6682C5.54339 13.8829 6.26072 14 7.00244 14C10.8672 14 14 10.8672 14 7.00244C14 4.49425 12.6776 2.29836 10.6915 1.06378Z"
        fill="url(#paint0_radial)"
      />
      <defs xmlns="http://www.w3.org/2000/svg">
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.27074 3.80872) scale(6.79025)"
        >
          <stop stopColor="#43DFA7" />
          <stop offset="1" stopColor="#454E7E" />
        </radialGradient>
      </defs>
    </SvgIcon>
  );
};

export default withStyles(styles)(LogoSmallIcon);
