import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { RouteComponentProps, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import NoMatch from '@shared/components/NoMatch';
import Lazyload from '@shared/components/LazyLoad';
import ErrorHandler from '@shared/components/ErrorHandler';
import Header from './components/Header';
import { MergeTypes } from '@shared/types/common';
import ROUTES from '@Portal/shared/constants/routes';
import { getDefaultRoute } from '@Portal/shared/utils/common';
import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';
import LayoutStore from '@Portal/shared/stores/layout';

const Clinics = React.lazy(() => import('./submodules/Clinics'));

import styles from './Private.styles';

export interface PrivateModuleProps extends WithStyles<typeof styles>, RouteComponentProps {}

const PUBLIC_ROUTES = Object.values(ROUTES.public);
const HEADER_HEIGHT = 80;

@observer
class Private extends React.Component<PrivateModuleProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  @observable private appCrashed: boolean;

  componentDidCatch() {
    this.appCrashed = true;
  }

  private get routes(): Array<{
    routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
  }> {
    return [{ routeProps: { path: ROUTES.private.clinics.path, component: Clinics } }];
  }

  private get modules() {
    const { classes } = this.props;
    const redirectPathname = getDefaultRoute();

    return (
      <Lazyload classes={{ root: classes.moduleLoading }}>
        <Switch>
          {this.routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
            <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
          ))}
          <Redirect exact from={ROUTES.initial} to={redirectPathname} />
          {PUBLIC_ROUTES.map((route) => (
            <Redirect exact key={route} from={route} to={redirectPathname} />
          ))}
          <Route component={NoMatch} />
        </Switch>
      </Lazyload>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Header
            defaultRoute={getDefaultRoute()}
            height={HEADER_HEIGHT}
            moduleName={this.layoutStore.config?.headerProps?.moduleName}
            classes={{
              root: classes.header,
            }}
            profileLink={ROUTES.private.profile}
            backButtonProps={this.layoutStore.config?.headerProps?.backButtonProps}
            createButtonProps={this.layoutStore.config?.headerProps?.createBtnProps}
            onLogoutClick={this.authStore.logout}
          />
          <div
            className={classes.moduleWrapper}
            style={{ height: `calc(100% - ${HEADER_HEIGHT}px)` }}
          >
            {this.appCrashed ? <ErrorHandler /> : this.modules}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Private);
