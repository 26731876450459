import * as React from 'react';
import { hot } from 'react-hot-loader';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import ErrorHandler from '@shared/components/ErrorHandler';
import { NotificationType, showNotification } from '@shared/components/Notification';
import { browser } from '@shared/utils/browser';

import styles from './AppContainer.styles';

export interface AppContainerProps extends WithStyles<typeof styles>, RouteComponentProps {}

@hot(module)
@observer
class AppContainer extends React.Component<AppContainerProps> {
  @observable private appCrashed: boolean;

  componentDidCatch() {
    this.appCrashed = true;
  }

  componentDidMount() {
    if (browser?.name === 'ie') {
      showNotification(
        'For full experience we recommend you change your Internet Explorer browser for another.',
        NotificationType.warning,
        { autoClose: false }
      );
    }
  }

  render() {
    const { classes, children } = this.props;

    if (this.appCrashed) {
      return <ErrorHandler />;
    }

    return (
      <Typography component="div" className={classes.root}>
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(withRouter(AppContainer));
