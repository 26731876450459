import Form from 'mobx-react-form';

import { toLowerCaseFirst } from './common';
import { showNotification, NotificationType } from '@shared/components/Notification';

type Errors = { [key: string]: Array<string> };

export const retrieveValidationErrors = (errors: Errors) => {
  if (!errors) {
    return [];
  }

  return Object.keys(errors)
    .filter((errorFiled) => errors[errorFiled]?.length)
    .map((errorFiled) => {
      return {
        field: toLowerCaseFirst(errorFiled),
        message: errors[errorFiled][0],
      };
    });
};

export const showErrors = (errors: Errors, form: Form) => {
  retrieveValidationErrors(errors).forEach((err) => {
    const field = form.fields.get(err.field);

    if (field) {
      field.errorAsync = err.message;

      field.showErrors();
    } else {
      showNotification(err.message, NotificationType.error);
    }
  });
};

export const getScrollAttr = (fieldName: string) => ({ 'data-scroll-id': fieldName });

export const scrollToInvalidField = (fieldName: string, container = document) => {
  const input = container.querySelector(`[data-scroll-id="${fieldName}"]`);

  input?.scrollIntoView();
};

export const getInvalidFields = (form): Array<string> => {
  const retrieveFields = (fields) => {
    return Array.from(fields.values())
      .filter(({ hasError }) => hasError)
      .reduce<Array<string>>(
        (acc, { name, fields: nestedFields }) =>
          nestedFields.size ? [...acc, ...retrieveFields(nestedFields)] : [...acc, name],
        []
      );
  };

  return retrieveFields(form.fields);
};

export const deleteFields = (form, fields: Array<string>) => {
  fields.forEach((field) => form.del(field));
};

export const resetFields = (form, fields: Array<string>) => {
  fields.forEach((fieldName) => {
    form.$(fieldName).reset();
    form.$(fieldName).set('label', '');
    form.$(fieldName).set('extra', null);
  });
};

export const resetForm = (form) => {
  // TODO use resetFields
  form.reset();

  form.fields.forEach((field) => {
    field.set('label', '');
  });

  form.fields.forEach((field) => {
    field.set('extra', null);
  });
};
