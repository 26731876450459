import { createStyles, Theme } from '@material-ui/core';

export default ({ spacing, palette: { colors, primary }, radius }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '85vh',
      background: colors.white,
      borderRadius: radius.containers.secondary,
      padding: spacing(8),
    },
    title: {
      fontSize: 48,
      color: primary.main,
      fontWeight: 900,
    },
    message: {
      fontSize: 24,
      margin: spacing(4, 0, 6),
      color: colors.grey,
      fontWeight: 700,
    },
  });
