import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default function styles({ spacing, breakpoints }: Theme) {
  return createStyles({
    root: {
      fontSize: 16,
      padding: spacing(0, 3, 0, 1),
    },
    label: {},
    labelVariantText: {},
    iconWrapper: {
      fontWeight: 700,
      display: 'flex',
      marginRight: spacing(),
    },
    iconWrapperVariantText: {
      marginBottom: -2,
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      label: {
        lineHeight: 1,
      },
      iconWrapper: {
        marginBottom: 0,
        marginRight: 0,
      },
      iconWrapperVariantText: {
        marginRight: -spacing(2),
      },
    },
  });
}
