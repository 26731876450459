import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Lock.styles';

interface LockIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const LockIcon: React.FC<LockIconProps> = ({ classes }) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      color="primary"
      classes={{ root: classes.root }}
    >
      <path
        d="M18 10.7999H16.8V7.1999C16.8 4.5599 14.64 2.3999 12 2.3999C9.36 2.3999 7.2 4.5599 7.2 7.1999V10.7999H6C5.4 10.7999 4.8 11.3999 4.8 11.9999V20.3999C4.8 20.9999 5.4 21.5999 6 21.5999H18C18.6 21.5999 19.2 20.9999 19.2 20.3999V11.9999C19.2 11.3999 18.6 10.7999 18 10.7999ZM13.2 19.1999H10.8L11.28 16.5599C10.68 16.3199 10.2 15.5999 10.2 14.9999C10.2 14.0399 11.04 13.1999 12 13.1999C12.96 13.1999 13.8 14.0399 13.8 14.9999C13.8 15.7199 13.44 16.3199 12.72 16.5599L13.2 19.1999ZM14.4 10.7999H9.6V7.1999C9.6 5.8799 10.68 4.7999 12 4.7999C13.32 4.7999 14.4 5.8799 14.4 7.1999V10.7999Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(LockIcon);
