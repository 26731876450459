import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './CheckMark.styles';

interface CheckMarkIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const CheckMarkIcon: React.FC<CheckMarkIconProps> = ({ classes }) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" classes={{ root: classes.root }}>
      <path
        d="M9.00006 16.2003L5.50006 12.7003C5.40853 12.6076 5.29953 12.5341 5.17937 12.4839C5.05921 12.4337 4.93028 12.4078 4.80006 12.4078C4.66983 12.4078 4.5409 12.4337 4.42074 12.4839C4.30058 12.5341 4.19158 12.6076 4.10006 12.7003C4.00742 12.7918 3.93387 12.9008 3.88367 13.0209C3.83347 13.1411 3.80762 13.27 3.80762 13.4003C3.80762 13.5305 3.83347 13.6594 3.88367 13.7796C3.93387 13.8997 4.00742 14.0087 4.10006 14.1003L8.29006 18.2903C8.68006 18.6803 9.31006 18.6803 9.70006 18.2903L20.3001 7.70025C20.3927 7.60873 20.4663 7.49973 20.5165 7.37957C20.5667 7.25941 20.5925 7.13048 20.5925 7.00025C20.5925 6.87003 20.5667 6.7411 20.5165 6.62094C20.4663 6.50078 20.3927 6.39177 20.3001 6.30025C20.2085 6.20761 20.0995 6.13406 19.9794 6.08386C19.8592 6.03366 19.7303 6.00781 19.6001 6.00781C19.4698 6.00781 19.3409 6.03366 19.2207 6.08386C19.1006 6.13406 18.9916 6.20761 18.9001 6.30025L9.00006 16.2003Z"
        fill="#08B26A"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(CheckMarkIcon);
