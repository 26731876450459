export const boxShadow = {
  variant1: '0px 2px 4px rgba(0, 0, 0, 0.24)',
  variant2: '0px 2px 6px rgba(0, 0, 0, 0.16)',
  variant3: '0px 2px 4px rgba(0, 0, 0, 0.24)',
  variant4: '0px 4px 6px rgba(0, 0, 0, 0.24)',
  variant5: 'inset 0px 1px 2px rgba(0, 0, 0, 0.16)',
  variant6: '0px 8px 16px rgba(0, 0, 0, 0.16)',
  variant7: '0px 1px 2px rgba(0, 0, 0, 0.08)',
  variant8: '0px 2px 4px rgba(0, 0, 0, 0.08)',
};
