import { injectable } from 'inversify';
import { action, computed, observable } from 'mobx';
import { BackButtonProps } from '@shared/components/BackButton';
import { ButtonProps } from '@shared/components/Button';

export interface LayoutConfig {
  headerProps?: {
    moduleName?: string;
    backButtonProps?: Partial<BackButtonProps>;
    createBtnProps?: Partial<ButtonProps>;
  };
}

const DEFAULT_CONFIG = undefined;

@injectable()
export default class LayoutStore {
  static diToken = Symbol('layout-store');

  @observable private _config: LayoutConfig | undefined = DEFAULT_CONFIG;

  @computed get config() {
    return this._config;
  }

  @action setConfig = (config: Partial<LayoutConfig>) => {
    return (this._config = config);
  };

  @action setDefaultConfig = () => {
    this._config = DEFAULT_CONFIG;
  };
}
