import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { TextMaskInput } from './TextMaskInput';
import MuiTextField, { StandardTextFieldProps } from '@material-ui/core/TextField';
import { TextFieldMask } from './maskFormats';
import FormLabel from '@material-ui/core/FormLabel';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Field } from 'mobx-react-form';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import cx from 'classnames';

import Ellipsis from '@shared/components/Ellipsis';
import PasswordRequirements, {
  PasswordRequirementsProps,
} from '@shared/components/PasswordRequirements';

import styles from './TextField.styles';

export enum NormalizeType {
  onChange,
  onBlur,
}

export interface TextFieldProps
  extends Omit<StandardTextFieldProps, 'classes'>,
    WithStyles<typeof styles> {
  field?: Field;
  label?: React.ReactNode;
  mask?: TextFieldMask;
  withPasswordToggle?: boolean;
  withPasswordRequirements?: boolean;
  passwordRequirementsProps?: Partial<PasswordRequirementsProps>;
  normalizeType?: NormalizeType;
  normalize?: (value: string) => string | number;
  isValid?: (value: string) => boolean;
}

type Size = TextFieldProps['size'];

const defaultSize = 'medium';

export const normalizeNumber = (value: string): string | number => {
  const numberValue = Number(value);
  const isNumber = value && !isNaN(numberValue);

  if (isNumber) {
    return numberValue;
  }

  return value;
};

@observer
class TextField extends React.Component<TextFieldProps> {
  static defaultProps = {
    size: defaultSize as Size,
  };

  @observable private showPassword = false;
  @observable private shouldShowPasswordRequirements = false;

  private get maskConfig() {
    const { mask } = this.props;

    const maskConfig = {
      component: { inputComponent: TextMaskInput },
      settings: { maskType: mask },
    };

    return mask ? maskConfig : undefined;
  }

  private get endAdornment() {
    const { withPasswordToggle, classes } = this.props;

    if (withPasswordToggle) {
      return (
        <InputAdornment position="end">
          <IconButton
            className={classes.showPasswordBtn}
            aria-label="Toggle password visibility"
            onClick={this.handleClickShowPassword}
          >
            {this.showPassword ? (
              <VisibilityOffIcon classes={{ root: classes.showPasswordIcon }} />
            ) : (
              <VisibilityIcon classes={{ root: classes.showPasswordIcon }} />
            )}
          </IconButton>
        </InputAdornment>
      );
    }

    return null;
  }

  private handleClickShowPassword = () => {
    this.showPassword = !this.showPassword;
  };

  private isValidValue = (value: string) => {
    const { isValid } = this.props;

    if (!isValid) {
      return true;
    }

    return isValid(value);
  };

  private handleFocus = (e) => {
    e.persist();

    const { withPasswordRequirements, onFocus } = this.props;

    if (withPasswordRequirements) {
      this.shouldShowPasswordRequirements = true;
    }

    if (onFocus) {
      onFocus(e);
    }

    const { field } = this.props;

    if (field) {
      field.onFocus(e);
    }
  };

  private handleBlur = (e) => {
    e.persist();

    const { field, withPasswordRequirements, normalize, onBlur } = this.props;
    const { value } = e.target;

    if (!this.isValidValue(value)) {
      return;
    }

    if (withPasswordRequirements) {
      this.shouldShowPasswordRequirements = false;
    }

    if (onBlur) {
      onBlur(e);
    }

    if (field) {
      field.onBlur(e);

      if (normalize) {
        field.set('value', normalize(value));
        field.validate();
      }
    }
  };

  private handleChange = (e) => {
    e.persist();

    const { field, normalizeType, normalize = (val) => val, onChange } = this.props;
    const { value } = e.target;

    if (!this.isValidValue(value)) {
      return;
    }

    if (onChange) {
      onChange(e);
    }

    if (field) {
      const finalValue = normalizeType === NormalizeType.onChange ? normalize(value) : value;

      field.set('value', finalValue);
    }
  };

  render() {
    const {
      field,
      label,
      classes,
      InputProps,
      type,
      size = defaultSize,
      fullWidth,
      normalizeType,
      onBlur,
      onChange,
      normalize,
      helperText,
      withPasswordToggle,
      withPasswordRequirements,
      passwordRequirementsProps,
      isValid,
      ...otherProps
    } = this.props;

    const {
      showPasswordBtn,
      showPasswordIcon,
      root: rootClass,
      labelRoot: labelRootClass,
      rootFullWidth: rootFullWidthClass,
      errorText: errorTextClass,
      medium: mediumClass,
      small: smallClass,
      passwordRequirements: passwordRequirementsClass,
      ...otherClasses
    } = classes;

    const sizeClasses = {
      medium: classes.medium,
      small: classes.small,
    };

    const errorText = helperText || field?.error;
    const hasError = Boolean(helperText || field?.error);

    return (
      <div className={cx(rootClass, { [rootFullWidthClass]: fullWidth })}>
        {label && (
          <FormLabel classes={{ root: labelRootClass }} component="legend">
            {label}
          </FormLabel>
        )}
        <MuiTextField
          {...(field ? field.bind() : {})}
          fullWidth
          type={
            withPasswordToggle ? (this.showPassword ? 'text' : 'password') : field?.type || type
          }
          variant="filled"
          error={hasError}
          helperText={null}
          classes={otherClasses}
          InputProps={{
            classes: {
              root: sizeClasses[size],
            },
            disableUnderline: true,
            endAdornment: this.endAdornment,
            ...this.maskConfig?.component,
            ...InputProps,
          }}
          inputProps={{
            ...this.maskConfig?.settings,
          }}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          {...otherProps}
        />
        {this.shouldShowPasswordRequirements && (
          <PasswordRequirements
            value={otherProps.value || field?.value}
            classes={{
              root: passwordRequirementsClass,
            }}
            {...passwordRequirementsProps}
          />
        )}
        {hasError && <Ellipsis classes={{ root: errorTextClass }} text={errorText} />}
      </div>
    );
  }
}

export default withStyles(styles)(TextField);
