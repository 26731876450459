import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      width: '100%',
      padding: spacing(6, 0, 0, 24),
    },
  });
}
