import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Flex, { FlexProps } from '@shared/components/Flex';

import styles from './AuthFormFooter.styles';

export interface AuthFormFooterProps
  extends WithStyles<typeof styles>,
    Omit<FlexProps, 'classes'> {}

const AuthFormFooter: React.FC<AuthFormFooterProps> = ({ classes, ...otherProps }) => {
  return (
    <Flex
      alignItems="center"
      justify="space-between"
      classes={{ root: classes.root }}
      {...otherProps}
    />
  );
};

export default withStyles(styles)(AuthFormFooter);
