export const colors = {
  white: '#FFFFFF',
  text: '#212121',
  secondary: '#FFFFFF',
  secondaryDisabled: '#F6F6F6',
  red: '#FF0000',
  redLight: '#FF00000A',
  error: '#FF0000',
  black: '#000000',
  green: '#08B26A',
  greenLight: '#08B26A0A',
  yellow: '#FFCB58',
  yellowLight: '#FFCB580A',
  grey: '#707070',
  grey2: '#A9A9A9',
  greyLight: '#F6F6F6',
  greyLight2: '#E0E0E0',
  greyLight3: '#E5E5E5',
  greyLight4: '#F7F7F6',
  greyLight5: '#EEEEEE',
  greyLight6: '#FCFCFC',
  greyDark: '#364859',
  blue: '#3263D9',
  purple: '#454E7E',
};
