import { action, observable, toJS } from 'mobx';
import { ClinicFont } from '@shared/constants/clinicDesign';

export enum WorkingDay {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
}

export interface WorkingTime {
  from: string;
  to: string;
}

export enum WorkingHourType {
  am = 1,
  pm = 2,
}

type WorkingHourAM = {
  dayOfWeek: Array<WorkingDay>;
  am: WorkingTime;
  pm: undefined;
};

type WorkingHourPM = {
  dayOfWeek: Array<WorkingDay>;
  am: undefined;
  pm: WorkingTime;
};

type WorkingHourAllTime = {
  dayOfWeek: Array<WorkingDay>;
  am: WorkingTime;
  pm: WorkingTime;
};

export type WorkingHour = WorkingHourAllTime | WorkingHourPM | WorkingHourAM;

export interface UserInterfaceConfiguration {
  color?: string;
  font?: ClinicFont;
  logo?: string;
  logoUrl?: string;
}

export interface ClinicDTO {
  id: number;
  name: string;
  creationDate: string;
  address: string;
  phoneNumber: string;
  website: string;
  domainName: string;
  workingHours: Array<WorkingHour>;
  logoUrl?: string;
  inClinicFee: number;
  remoteFee: number;
  userInterfaceConfiguration: UserInterfaceConfiguration;
}

export default class Clinic {
  id: number;
  name: string;
  creationDate: string;
  address: string;
  phoneNumber: string;
  website: string;
  domainName: string;
  logoUrl?: string;
  inClinicFee: number;
  remoteFee: number;
  userInterfaceConfiguration: UserInterfaceConfiguration;
  @observable workingHours: Array<WorkingHour>;

  constructor(dto: Partial<ClinicDTO>) {
    this.update(dto);
  }

  get asJson(): ClinicDTO {
    return {
      id: this.id,
      name: this.name,
      creationDate: this.creationDate,
      address: this.address,
      phoneNumber: this.phoneNumber,
      website: this.website,
      domainName: this.domainName,
      logoUrl: this.logoUrl,
      inClinicFee: this.inClinicFee,
      remoteFee: this.remoteFee,
      userInterfaceConfiguration: this.userInterfaceConfiguration,
      workingHours: toJS(this.workingHours),
    };
  }

  get scheduledWorkingDays() {
    return this.workingHours.reduce((acc, { dayOfWeek }) => [...acc, ...dayOfWeek], []);
  }

  get allWorkingDaysAreScheduled() {
    const daysInWeek = 7;

    return this.scheduledWorkingDays.length === daysInWeek;
  }

  @action update(newData: Partial<ClinicDTO>) {
    const allData = { ...this.asJson, ...newData };

    Object.assign(this, allData);

    return this.asJson;
  }
}
