export const REQUEST_PARAMS = {
  items: 'items',
  pagination: {
    name: 'pagination',
    fields: {
      page: 'page',
      pageSize: 'pageSize',
    },
  },
  sorting: {
    name: 'sort',
    fields: {
      field: 'field',
      type: 'type',
    },
  },
  pageInfo: {
    name: 'pageInfo',
    fields: {
      totalCount: 'totalCount',
      hasPreviousPage: 'hasPreviousPage',
      hasNextPage: 'hasNextPage',
    },
  },
  searchKeyword: 'searchKeyword',
};

export enum SortingType {
  asc = 'asc',
  desc = 'desc',
}
