import 'core-js/es/symbol';
import 'core-js/es/promise';
import 'core-js/es/string/includes';
import 'core-js/es/array/from';
import 'core-js/es/array/find';
import 'core-js/es/array/fill';
import 'core-js/es/array/includes';
import 'core-js/es/array/find-index';
import 'core-js/es/object/entries';
import 'core-js/es/object/values';
import 'core-js/es/object/keys';
import 'core-js/es/object/assign';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/string/starts-with';
import 'core-js/es/string/ends-with';
import 'core-js/es/number/is-integer';
import 'core-js/es/typed-array';
import 'core-js/web';
