import { createStyles, Theme } from '@material-ui/core/styles';

export default ({ palette: { colors } }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: 400,
      maxHeight: '80vh',
    },
  });
