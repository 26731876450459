import { injectable } from 'inversify';
import { observable, computed, action } from 'mobx';
import decodeJWT from 'jwt-decode';
import Cookie from 'js-cookie';

import container from '@Portal/core/di-container';
import AuthService from '@Portal/shared/services/auth';
import SystemUser, { SystemUserDTO } from '@Portal/shared/models/system-user';
import { Id } from '@shared/types/common';

const COOKIES_KEYS = {
  tokens: {
    access: 'pd_portal_access_token',
    refresh: 'pd_portal_refresh_tToken',
  },
};

export enum TokenRefreshStatus {
  refreshing,
  refreshed,
  initial,
}

@injectable()
export default class AuthStore {
  static diToken = Symbol('auth-store');
  private service = container.get<AuthService>(AuthService.diToken);
  @observable tokenRefreshStatus = TokenRefreshStatus.initial;
  @observable private _user;

  @computed get user(): SystemUser {
    return this._user;
  }

  @computed get loggedIn(): boolean {
    return Boolean(this.user);
  }

  get tokens() {
    return {
      refresh: Cookie.get(COOKIES_KEYS.tokens.refresh),
      access: Cookie.get(COOKIES_KEYS.tokens.access),
    };
  }

  initialize() {
    this.setUser();
  }

  @action private setUser() {
    const token = Cookie.get(COOKIES_KEYS.tokens.access);

    if (!token) {
      return;
    }

    try {
      const data = decodeJWT<SystemUserDTO>(token);

      this._user = new SystemUser(data);
    } catch {
      this.logout();
    }
  }

  private setTokens(tokens: { accessToken: string; refreshToken: string }) {
    Cookie.set(COOKIES_KEYS.tokens.access, tokens.accessToken);
    Cookie.set(COOKIES_KEYS.tokens.refresh, tokens.refreshToken);
  }

  private setAuthData(tokens: { accessToken: string; refreshToken: string }) {
    this.setTokens(tokens);
    this.setUser();
  }

  async login(data: { username: string; password: string }) {
    const authData = await this.service.login(data);

    this.setAuthData(authData);
  }

  @action refreshToken = async () => {
    if (this.tokenRefreshStatus === TokenRefreshStatus.refreshing) {
      return;
    }

    if (!this.tokens.refresh) {
      this.logout();

      throw new Error('User is unauthorized');
    }

    this.tokenRefreshStatus = TokenRefreshStatus.refreshing;

    try {
      const authData = await this.service.refreshToken(this.tokens.refresh);

      this.setAuthData(authData);

      this.tokenRefreshStatus = TokenRefreshStatus.refreshed;
    } catch (err) {
      this.logout();
      throw err;
    }
  };

  logout = () => {
    this.reset();
  };

  private resetTokens() {
    Cookie.remove(COOKIES_KEYS.tokens.access);
    Cookie.remove(COOKIES_KEYS.tokens.refresh);
  }

  resetPassword(email: string) {
    return this.service.resetPassword(email);
  }

  validatePasswordRecoveryLink(userId: Id, token: string) {
    return this.service.validatePasswordRecoveryLink(userId, token);
  }

  setNewPassword(userId: Id, data: { newPassword: string; token: string }) {
    return this.service.setNewPassword(userId, data);
  }

  @action private reset() {
    this.resetTokens();

    this.tokenRefreshStatus = TokenRefreshStatus.initial;
    this._user = undefined;
  }
}
