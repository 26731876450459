import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core';
import { RouteComponentProps, Switch, Route, RouteProps, Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import Login from './submodules/Login';
import ForgotPassword from './submodules/ForgotPassword';
import NewPassword from './submodules/NewPassword';
import Flex from '@shared/components/Flex';
import Lazyload from '@shared/components/LazyLoad';
import ROUTES from '@Portal/shared/constants/routes';

import styles from './Public.styles';

export interface PublicProps extends WithStyles<typeof styles>, RouteComponentProps {}

class Public extends React.Component<PublicProps> {
  get routes(): Array<RouteProps> {
    const { login, forgotPassword, newPassword } = ROUTES.public;

    return [
      { path: login, component: Login },
      { path: forgotPassword, component: ForgotPassword },
      { path: newPassword, component: NewPassword },
    ];
  }

  render() {
    const { classes } = this.props;

    return (
      <Flex alignItems="center" justify="center" className={classes.root}>
        <Paper classes={{ root: classes.container }}>
          <Lazyload classes={{ root: classes.moduleLoading }}>
            <Switch>
              {this.routes.map((routeProps) => (
                <Route key={String(routeProps.path)} {...routeProps} />
              ))}
              <Redirect to={ROUTES.public.login} />
            </Switch>
          </Lazyload>
        </Paper>
      </Flex>
    );
  }
}

export default withStyles(styles)(Public);
