import { createStyles, Theme } from '@material-ui/core';

export default ({ spacing, palette: { colors, primary }, radius }: Theme) =>
  createStyles({
    root: {
      padding: spacing(3, 0),
      minHeight: 100,
      borderRadius: radius.containers.primary,

      '& svg': {
        fontSize: 40,
        opacity: 1,
        fill: primary.main,
      },
    },
    heading: {
      fontSize: 16,
      margin: spacing(0, 0, 1),
      textAlign: 'center',
      color: colors.black,
    },
    subheading: {
      margin: 0,
      fontSize: 13,
      textAlign: 'center',
      color: colors.greyLight,
    },
    button: {
      marginTop: spacing(3),
    },
  });
