import { createStyles, Theme } from '@material-ui/core/styles';

import { BREAKPOINT } from '@Clinic/core/Layout/Layout.constants';

export default ({ breakpoints }: Theme) =>
  createStyles({
    root: {
      minWidth: 552,
    },
    content: {
      fontSize: 15,
    },
    actions: {
      justifyContent: 'flex-start',
    },

    [breakpoints.down(BREAKPOINT.mobile)]: {
      root: {
        minWidth: 'auto',
      },
    },
  });
