import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';

import Loading, { LoadingProps } from '@shared/components/Loading';

import styles from './LazyLoad.styles';

export interface LazyLoadProps extends WithStyles<typeof styles> {
  withFallback?: boolean;
  component?: React.ComponentType<any>;
  loadingProps?: Partial<LoadingProps>;
}

class LazyLoad extends React.Component<LazyLoadProps> {
  static defaultProps = {
    withFallback: true,
  };

  render() {
    const {
      component: Component,
      classes,
      children,
      withFallback,
      loadingProps,
      ...otherProps
    } = this.props;

    return (
      <React.Suspense
        fallback={
          withFallback ? (
            <Loading classes={{ root: classes.root }} size={40} {...loadingProps} />
          ) : null
        }
      >
        {Component ? <Component {...otherProps} /> : children}
      </React.Suspense>
    );
  }
}

export default withStyles(styles)(LazyLoad);
