import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { colors } from './colors';
import { radius } from './radius';

export const scrollStyles: CreateCSSProperties = {
  '&::-webkit-scrollbar': {
    width: 6,
    height: 6,
  },

  '&::-webkit-scrollbar-track': {
    borderRadius: radius.containers.primary,
    backgroundColor: colors.greyLight,
    margin: 1,
  },

  '&::-webkit-scrollbar-thumb': {
    background: colors.grey2,
    borderRadius: radius.containers.primary,
  },

  '&::-webkit-scrollbar-thumb:hover': {
    background: colors.grey,
  },

  scrollbarColor: `${colors.greyLight} ${colors.greyLight}`,
  scrollbarWidth: 'thin',
};
