import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors, secondary } }: Theme) {
  return createStyles({
    root: {
      minWidth: 250,
      backgroundColor: secondary.main,
      color: colors.black,
      padding: spacing(4),
      zIndex: 1,
    },
    heading: {
      fontWeight: 700,
      fontSize: 14,
    },
    requirements: {
      marginTop: spacing(2),
      fontSize: 14,
    },
    indicator: {
      width: 4,
      height: 4,
      background: colors.green,
      borderRadius: '50%',
      marginRight: spacing(3),
    },
    indicatorSuccess: {
      background: colors.green,
    },
    indicatorError: {
      background: colors.error,
    },
  });
}
