import moment from 'moment';

export const USER_FIELDS = {
  dateOfBirth: 'dateOfBirth',
  email: 'email',
  firstName: 'firstName',
  id: 'id',
  lastName: 'lastName',
  newPassword: 'newPassword',
  newPasswordConfirm: 'newPasswordConfirm',
  password: 'password', // TODO remove and add locally to form files
  oldPassword: 'oldPassword',
  phoneNumber: 'phoneNumber',
  gender: 'gender',
  username: 'username',
  fullName: 'fullName',
  familyMembersCount: 'familyMembersCount',
  dentist: {
    name: 'dentist',
    fields: {
      id: 'id',
      fullName: 'fullName',
    },
  },
};

export const PATIENT_MIN_AGE = 18;
export const PATIENT_MINIMUM_ACCEPTABLE_BIRTH_DATE = moment
  .utc()
  .startOf('day')
  .subtract(PATIENT_MIN_AGE, 'years')
  .toISOString();
