import { createStyles, Theme } from '@material-ui/core';

export default ({ palette: { colors }, spacing }: Theme) =>
  createStyles({
    root: {
      padding: 0,
      marginBottom: spacing(5),
    },
    heading: {
      fontSize: 24,
      fontWeight: 700,
    },
    closeIcon: {
      padding: 0,
    },
  });
