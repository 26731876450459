import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles, WithStyles } from '@material-ui/core';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import PrivateModule from '@Portal/modules/Private';
import PublicModule from '@Portal/modules/Public';
import ErrorHandler from '@shared/components/ErrorHandler';
import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';

import styles from './Portal.styles';

export interface PortalProps extends WithStyles<typeof styles>, RouteComponentProps {}

@observer
class Portal extends React.Component<PortalProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  @observable private appCrashed: boolean;

  componentDidCatch() {
    this.appCrashed = true;
  }

  render() {
    const { classes } = this.props;

    if (this.appCrashed) {
      return <ErrorHandler />;
    }

    const { loggedIn } = this.authStore;

    return (
      <Typography component="div" className={classes.root}>
        <Route path="/" component={loggedIn ? PrivateModule : PublicModule} />
      </Typography>
    );
  }
}

export default withStyles(styles)(withRouter(Portal));
