import * as React from 'react';
import { Form as BaseForm } from 'mobx-react-form';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Path, LocationDescriptorObject } from 'history';
import cx from 'classnames';

import Loading from '@shared/components/Loading';
import Button, { ButtonProps } from '@shared/components/Button';
import ErrorHandler from '@shared/components/ErrorHandler';
import { showNotification } from '@shared/components/Notification';
import FormUnit, { FormUnitMargin } from './components/FormUnit';
import { ManageMode } from '@shared/constants/common';
import { showErrors } from '@shared/utils/form';
import history from '@shared/utils/history';

import styles from './Form.styles';

type Data = { [key: string]: any };

export interface FormProps extends WithStyles<typeof styles> {
  formInstance: BaseForm;
  notificationText?: string;
  manageMode?: ManageMode;
  closeLocation?: Path | LocationDescriptorObject;
  loading?: boolean;
  closeOnSubmit?: boolean;
  withControls?: boolean;
  loadingItemError?: boolean;
  controlsMargin?: FormUnitMargin;
  withCancelButton?: boolean;
  footerFullWidth?: boolean;
  buttonProps?: {
    submit?: Partial<ButtonProps>;
    cancel?: Partial<ButtonProps>;
  };
  onSubmit: (data: Data) => Promise<any> | any;
  closeForm?: () => any;
}

@observer
class Form extends React.Component<FormProps> {
  static defaultProps = {
    withControls: true,
    withCancelButton: true,
    closeOnSubmit: true,
    controlsMargin: FormUnitMargin.large,
  };
  @observable submitting = false;

  private handleSubmit = async (e) => {
    e.preventDefault();

    const { formInstance, notificationText } = this.props;

    formInstance.validate();

    if (formInstance.hasError) {
      formInstance.showErrors();

      return;
    }

    const data = formInstance.values();
    const { onSubmit, closeForm, closeOnSubmit, closeLocation } = this.props;

    try {
      this.submitting = true;

      await onSubmit(data);

      if (closeForm && closeOnSubmit) {
        closeForm();
      }

      if (notificationText) {
        showNotification(notificationText);
      }

      if (closeLocation) {
        history.push(closeLocation);
      }
    } catch (err) {
      if ([400, 403].includes(err?.response?.status)) {
        showErrors(err?.response?.data?.errors, formInstance);
      }
    } finally {
      this.submitting = false;
    }
  };

  private get config() {
    const { manageMode } = this.props;
    const config = {
      [ManageMode.create]: {
        buttons: {
          submit: {
            text: 'Create',
          },
        },
      },
      [ManageMode.edit]: {
        buttons: {
          submit: {
            text: 'Save',
          },
        },
      },
    };

    return manageMode ? config[manageMode] : undefined;
  }

  render() {
    const {
      classes,
      loading,
      children,
      withControls,
      controlsMargin,
      withCancelButton,
      footerFullWidth,
      loadingItemError,
      buttonProps,
      closeForm,
    } = this.props;

    return (
      <form className={classes.root} onSubmit={this.handleSubmit}>
        {loading && <Loading absolute />}
        {loadingItemError ? <ErrorHandler /> : <div className={classes.children}>{children}</div>}
        {withControls && !loadingItemError && (
          <FormUnit
            margin={controlsMargin}
            classes={{
              component: cx(classes.controlsFormUnitComponent, {
                [classes.controlsFormUnitComponentFullWidth]: footerFullWidth,
              }),
              label: cx(classes.controlsFormUnitLabel, {
                [classes.controlsFormUnitLabelFullWidth]: footerFullWidth,
              }),
            }}
          >
            <>
              <Button
                fullWidth={footerFullWidth}
                loading={this.submitting}
                type="submit"
                text={this.config?.buttons.submit.text}
                classes={{ root: cx(classes.submitButton, classes.buttonControl) }}
                {...buttonProps?.submit}
              />
              {withCancelButton && (
                <Button
                  color="secondary"
                  text="Cancel"
                  onClick={closeForm}
                  classes={{ root: classes.buttonControl }}
                  {...buttonProps?.cancel}
                />
              )}
            </>
          </FormUnit>
        )}
      </form>
    );
  }
}

export default withStyles(styles)(Form);
