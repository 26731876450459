import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ palette: { colors }, spacing }: Theme) {
  return createStyles({
    root: {
      height: '100vh',
      backgroundColor: colors.greyLight,
    },
    moduleLoading: {
      paddingTop: 100,
      paddingBottom: 100,
      minHeight: 300,
    },
    content: {
      height: '100%',
      margin: spacing(0, 4),
    },
    header: {},
    moduleWrapper: {},
  });
}
