import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Button, { ButtonProps } from '@shared/components/Button';
import DialogTitle from '@shared/components/DialogTitle';

import styles from './Confirmation.styles';

type ButtonsProps = {
  confirm: Partial<ButtonProps>;
  cancel: Partial<ButtonProps>;
};

export interface ConfirmationProps extends WithStyles<typeof styles> {
  buttonProps?: ButtonsProps;
}

interface ConfirmationState {
  open: boolean;
  withCloseButton?: boolean;
  title: React.ReactNode;
  question: React.ReactNode;
  buttonProps: ButtonsProps;
  callback: (isConfirmed?: boolean) => void;
}

let showConfirmationFn;

export function showConfirmation(
  {
    withCloseButton,
    title,
    question,
    buttonProps,
  }: {
    withCloseButton?: ConfirmationState['withCloseButton'];
    title: ConfirmationState['title'];
    question: ConfirmationState['question'];
    buttonProps?: ButtonsProps;
  },
  callback: (isConfirmed: boolean) => void
): void {
  showConfirmationFn({ title, question, buttonProps, withCloseButton }, callback);
}

const DEFAULT_STATE = {
  open: false,
  withCloseButton: true,
  title: '',
  question: '',
  buttonProps: {
    confirm: {
      text: 'Yes',
    },
    cancel: {
      text: 'No',
    },
  },
  callback: (isConfirmed?: boolean): void => {},
};

class Confirmation extends React.Component<ConfirmationProps, ConfirmationState> {
  readonly state = DEFAULT_STATE;

  componentDidMount() {
    showConfirmationFn = this.show;
  }

  private show = (
    {
      title,
      question,
      buttonProps,
      withCloseButton,
    }: {
      title: ConfirmationState['title'];
      question: ConfirmationState['question'];
      buttonProps?: ButtonsProps;
      withCloseButton?: ConfirmationState['withCloseButton'];
    },
    callback: (isConfirmed: boolean) => void
  ): void => {
    this.setState((state) => ({
      title,
      question,
      callback,
      withCloseButton,
      open: true,
      buttonProps: { ...state.buttonProps, ...buttonProps },
    }));
  };

  private terminate = () => {
    this.state.callback(false);
    this.hide();
  };

  private hide = () => {
    this.setState(
      (state) => ({ ...state, open: false }),
      () => {
        const timeToHideConfirmation = 500;

        setTimeout(() => this.setState(DEFAULT_STATE), timeToHideConfirmation);
      }
    );
  };

  private handleYes = () => {
    this.state.callback(true);
    this.hide();
  };

  render() {
    const { classes } = this.props;
    const { open, title, question, buttonProps, withCloseButton } = this.state;

    return (
      <Dialog open={open} classes={{ paper: classes.root }} maxWidth="sm">
        <DialogTitle heading={title} withCloseButton={withCloseButton} onClose={this.terminate} />
        <p className={classes.content}>{question}</p>
        <DialogActions classes={{ root: classes.actions }}>
          <Button text={buttonProps.confirm.text} onClick={this.handleYes} />
          <Button text={buttonProps.cancel.text} color="secondary" onClick={this.terminate} />
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Confirmation);
