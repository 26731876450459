import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
// You can add custom formatter fn (pipe) for custom mask
// https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
// https://github.com/text-mask/text-mask/tree/master/addons/src

export enum TextFieldMask {
  phoneNumber = 'phoneNumber',
  hoursAndMinutes = 'hoursAndMinutes',
  strictInteger = 'strictInteger',
}

export interface TextFieldMaskConfigProps {
  pipe?: (...args: any) => any;
  mask?: Array<string | RegExp> | ((value: string) => Array<string | RegExp>);
}

export type TextFieldMaskConfig = {
  [key in TextFieldMask]: TextFieldMaskConfigProps;
};

const BASE_PHONE_NUMBER_MASK = [
  '+',
  /[1-9]/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const getFormatNumberMaskByCountry = (firstNumber: number, secondNumber: number) => {
  const mask = [...BASE_PHONE_NUMBER_MASK];
  const first = new RegExp(String(firstNumber));
  const second = new RegExp(String(secondNumber));

  mask[1] = first;
  mask[2] = second;

  return mask;
};

export const textFieldMask: TextFieldMaskConfig = {
  // +[1-9]x xxxx xxxxxx
  [TextFieldMask.phoneNumber]: {
    mask: BASE_PHONE_NUMBER_MASK,
  },
  // full correct regexp - ^([01][0-9]|2[0-3]):([0-5][0-9])$
  // with auto correct on first number - if number > 2 : add 0 in start
  [TextFieldMask.hoursAndMinutes]: {
    pipe: createAutoCorrectedDatePipe('HH:MM'),
    mask: [/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
  },

  [TextFieldMask.strictInteger]: {
    mask: (rawValue) => {
      if (rawValue.length === 0) {
        return [];
      }

      return [/[1-9]/].concat(Array(rawValue.length - 1).fill(/[\d]/));
    },
  },
};
