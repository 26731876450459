export enum DayStatus {
  today = 'today',
  tomorrow = 'tomorrow',
  past = 'past',
  default = 'default',
}

export enum ManageMode {
  create = 'create',
  edit = 'edit',
  view = 'view',
}
