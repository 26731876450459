import Axios from 'axios';
import { injectable } from 'inversify';
import qs from 'qs';

import { Id } from '@shared/types/common';

@injectable()
export default class AuthService {
  static diToken = Symbol('auth-service');

  private urlPrefix = '/auth';
  private usersUrlPrefix = '/users';

  async login(credentials: { username: string; password: string }) {
    const {
      data: { access_token, refresh_token },
    } = await Axios.post(this.urlPrefix, qs.stringify({ grant_type: 'password', ...credentials }), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    return { accessToken: access_token, refreshToken: refresh_token };
  }

  async refreshToken(token: string) {
    const {
      data: { access_token, refresh_token },
    } = await Axios.post(
      this.urlPrefix,
      qs.stringify({ grant_type: 'refresh_token', refresh_token: token }),
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    );

    return { accessToken: access_token, refreshToken: refresh_token };
  }

  resetPassword(email: string) {
    const url = `${this.usersUrlPrefix}/password/reset/request`;

    return Axios.post(url, email);
  }

  validatePasswordRecoveryLink(userId: Id, token: string) {
    const url = `${this.usersUrlPrefix}/${userId}/password/reset/token/validate?passwordRecoveryToken=${token}`;

    return Axios.put(url);
  }

  setNewPassword(userId: Id, data: { newPassword: string; token: string }) {
    const url = `${this.usersUrlPrefix}/${userId}/password/reset`;

    return Axios.put(url, data);
  }
}
