import { fade, ThemeOptions } from '@material-ui/core/styles';

import { getShortcuts } from './shortcuts';
import { ThemeSettings } from '../theme.types';
import { colors, transitions, radius, spacing, boxShadow } from '../constants';
import { getHoverStyles } from '../utils';

export const getOverrides = (settings: ThemeSettings): ThemeOptions['overrides'] => {
  const { mainColor, fontFamily } = settings;
  const shortcuts = getShortcuts(settings);

  return {
    MuiButton: {
      root: {
        borderRadius: radius.controls,
        fontWeight: 500,
        textTransform: 'none',
        whiteSpace: 'nowrap',
        minWidth: 'auto',

        '&:hover': {
          opacity: 1,
        },

        '&$disabled': {
          opacity: 0.6,
        },
      },
      label: {
        fontWeight: 700,
      },
      sizeSmall: {
        fontSize: 12,
      },
      sizeLarge: {
        height: 48,
        padding: `0 ${spacing * 6}px`,
        fontSize: 16,
      },
      contained: {
        color: colors.grey,

        '&:hover': {
          backgroundColor: colors.greyLight,
          boxShadow: boxShadow.variant4,
        },

        '&$disabled': {
          opacity: 0.8,
          color: fade(colors.grey, 0.5),
          backgroundColor: colors.greyLight2,
          boxShadow: boxShadow.variant3,
        },
      },
      containedSizeSmall: {
        height: 32,
        padding: `0 ${spacing * 4}px`,
      },
      containedPrimary: {
        backgroundColor: mainColor,
        boxShadow: boxShadow.variant3,

        '&:hover': {
          backgroundColor: mainColor, // because Mui add darker affect
          boxShadow: boxShadow.variant4,
        },

        '&$disabled': {
          opacity: 1,
          color: fade(colors.white, 0.5),
          backgroundColor: mainColor,

          '&:hover': {
            backgroundColor: mainColor,
          },
        },
      },
      containedSecondary: {
        color: mainColor,
        backgroundColor: colors.white,
        boxShadow: boxShadow.variant3,

        '&:hover': {
          backgroundColor: colors.white,
          boxShadow: boxShadow.variant4,
        },

        '&$disabled': {
          opacity: 1,
          backgroundColor: colors.greyLight,
          color: fade(mainColor, 0.5),

          '&:hover': {
            backgroundColor: colors.greyLight,
          },
        },
      },
      outlined: {
        borderColor: mainColor,
      },
      outlinedSizeSmall: {
        height: 32,
        padding: `0 ${spacing * 4}px`,
      },
      outlinedPrimary: {
        borderColor: colors.error,
        color: colors.error,

        '&:hover': {
          boxShadow: boxShadow.variant4,
          color: 'white',
        },

        '&$disabled': {
          borderColor: colors.error,
          color: colors.error,
          opacity: 0.6,
        },
      },
      text: {
        '&$textSizeLarge': {
          padding: 0,
        },
      },
      textSizeLarge: {},
      textSizeSmall: {
        padding: 0,
      },
      textPrimary: {
        '&:hover': {
          opacity: 0.8,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTextField: {
      root: {
        position: 'relative',

        '& p': {
          // helper text
          marginTop: 0,
          position: 'absolute',
          top: '108%',
        },
      },
    },
    MuiFilledInput: {
      root: {
        padding: 1,
        borderTopLeftRadius: radius.containers.primary,
        borderTopRightRadius: radius.containers.primary,
        backgroundColor: colors.greyLight,

        '&$error, &$focused': {
          padding: 0,
        },

        '&$focused': {
          borderWidth: 2,
          borderColor: mainColor,
          backgroundColor: fade(mainColor, 0.08),
        },

        '&$error': {
          borderWidth: 2,
          borderColor: colors.error,
          backgroundColor: colors.redLight,
        },

        '&$disabled': {
          color: colors.grey,
          backgroundColor: colors.greyLight6,
        },
      },
      adornedEnd: {
        paddingRight: 1,
      },
      input: {
        height: '100%',
        padding: `0px 0px 0px ${spacing * 3}px`,
        display: 'flex',
        alignItems: 'center',

        '&:-webkit-autofill': {
          borderRadius: 'inherit',
        },
      },
      multiline: {
        padding: spacing * 4,

        '&$focused': {
          padding: spacing * 4 - 1,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: mainColor,
        },
        '&$focused $notchedOutline': {
          padding: 0,
          borderColor: mainColor,
        },
      },
      focused: {},
      notchedOutline: {
        top: 0,
        borderRadius: radius.controls,
        borderColor: mainColor,
        backgroundColor: '#fff',

        '& legend': {
          display: 'none',
        },
      },
      input: {
        paddingTop: 0,
        paddingLeft: spacing * 5,
        paddingBottom: 0,
        height: 48,
      },
      inputMarginDense: {
        paddingTop: 0,
        paddingBottom: 0,
        height: 36,
      },
    },
    MuiInput: {
      root: {
        backgroundColor: colors.greyLight,

        '&$error': {
          borderColor: colors.error,
        },
      },
      input: {
        paddingLeft: spacing * 3,
        height: '100%',

        '&::before, &::after': {
          borderBottom: 'none !important',
        },
      },
      underline: {
        '&::before, &::after': {
          borderBottom: 'none !important',
        },

        '&$disabled:before': {
          borderBottomStyle: 'solid',
          borderColor: colors.greyLight,
        },
      },
    },
    MuiInputBase: {
      root: {
        height: 48,
        fontSize: 16,
        borderRadius: radius.containers.primary,
        border: `1px solid ${colors.greyLight2}`,
        boxShadow: boxShadow.variant5,

        '&$disabled': {
          pointerEvents: 'none',
        },
      },
      multiline: {
        height: 'auto',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.text,
        fontSize: 12,
        fontWeight: 500,
      },
    },
    MuiSelect: {
      select: {
        zIndex: 1,

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        zIndex: 1,
      },
    },
    MuiCheckbox: {
      root: {
        padding: `0 ${spacing * 2}px`,
        color: colors.black,
        '&$checked': {
          color: mainColor,
        },
      },
      checked: {},
      colorSecondary: {
        '&$checked': {
          color: mainColor,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 'auto',
        padding: 0,

        '&$expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        alignItems: 'center',
        margin: 0,
        width: '100%',
        padding: `0 0 0 ${spacing * 9}px`,

        '&$expanded': {
          margin: 0,
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        backgroundColor: colors.greyLight2,
        padding: spacing * 5,
        border: `1px solid ${colors.greyLight}`,
        boxShadow: 'none',

        '&$expanded': {
          margin: 0,

          '&:last-child': {
            marginBottom: 'initial',
          },
        },
      },
      rounded: {
        borderRadius: radius.containers.primary,

        '&::before': {
          display: 'none',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: 1,
        ...shortcuts.errorText,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiSnackbarContent: {
      root: {
        minWidth: 'auto !important',
        flexWrap: 'nowrap',
        backgroundColor: 'white',
        padding: spacing * 4,
        boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.16)',
      },
      message: {
        fontFamily,
        padding: 0,
        color: colors.text,
        fontSize: 15,
        fontWeight: 500,
        width: '100%',
      },
      action: {
        paddingLeft: spacing * 4,
        marginRight: 0,
      },
    },
    MuiPaper: {
      root: {
        color: colors.black,
        padding: spacing * 6,
      },
      elevation1: {
        boxShadow: boxShadow.variant6,
      },
      elevation2: {
        boxShadow: boxShadow.variant7,
      },
      elevation3: {
        padding: 0,
      },
      elevation4: {
        boxShadow: boxShadow.variant2,
      },
    },
    MuiSwitch: {
      root: {
        width: 30,
        height: 18,
        padding: 0,
      },
      switchBase: {
        padding: 0,
        top: 2,
        left: 3,

        '& > span': {
          width: '95%',
        },
      },
      colorSecondary: {
        color: 'white',
        '&$checked': {
          color: 'white',
          left: -7,
        },
        '&$checked + $track': {
          backgroundColor: mainColor,
          opacity: 1,
        },
      },
      checked: {},
      track: {
        borderRadius: radius.controls + 2,
      },
      thumb: {
        width: 14,
        height: 14,
        borderRadius: 10,
      },
    },
    MuiTable: {
      root: {
        overflow: 'hidden',
      },
    },
    MuiTableRow: {
      root: {
        '&$hover:hover': {
          backgroundColor: fade(mainColor, 0.08),
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: `6px 4px 6px ${spacing * 4}px`,
        color: mainColor,
        fontSize: 15,
        height: 54,
        borderColor: colors.greyLight,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '&:last-of-type': {
          paddingRight: spacing * 4,
        },

        '& *': {
          whiteSpace: 'nowrap',
        },
      },
      head: {
        padding: `${spacing * 2}px ${spacing * 4}px`,
        backgroundColor: colors.greyLight2,
        height: 34,
        color: colors.grey,
        fontWeight: 400,
        whiteSpace: 'nowrap',

        '&:first-child': {
          borderTopLeftRadius: radius.containers.primary,
        },

        '&:last-child': {
          borderTopRightRadius: radius.containers.primary,
        },
      },
      stickyHeader: {
        backgroundColor: colors.secondary,
      },
      alignRight: {
        '& > *': {
          marginLeft: 'auto',
        },
      },
    },
    MuiTablePagination: {
      root: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: spacing * 4,
        overflow: 'hidden',
      },
      toolbar: {
        minHeight: 'auto',
        height: 42,
      },
      input: {
        marginLeft: 0,
      },
      spacer: {
        flex: 'none',
      },
    },
    MuiFormControlLabel: {
      root: {
        color: colors.grey,
        marginLeft: -6,
        paddingRight: spacing * 2,
        borderRadius: radius.containers.primary,

        ...getHoverStyles({
          supportedDeviceStyles: {
            background: colors.greyLight,
          },
        }),
      },
      label: {
        color: colors.black,
        fontSize: 16,
        fontWeight: 600,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        color: colors.black,
        transition: `${transitions.duration?.standard}s`,

        '&$selected': {
          backgroundColor: 'transparent',

          '&:hover': {
            backgroundColor: colors.greyLight2,
          },
        },
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
      },
    },
    MuiMenu: {
      paper: {
        padding: 0,
      },
      list: {},
    },
    MuiRadio: {
      root: {
        padding: 8,

        color: mainColor,

        '&$checked': {
          color: mainColor,
        },
      },
      checked: {},
      colorSecondary: {
        '&$checked': {
          color: mainColor,
        },
      },
    },
    MuiCard: {
      root: {
        backgroundColor: colors.greyLight2,
        border: `1px solid ${colors.greyLight}`,
        boxShadow: 'none',
        borderRadius: radius.containers.primary,
        padding: spacing * 6,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        paddingTop: spacing * 5,
        display: 'block',
      },
    },
    MuiDialog: {
      paper: {
        padding: `${spacing * 6}px ${spacing * 6}px ${spacing * 8}px ${spacing * 6}px`,

        '@media (max-width: 420px)': {
          margin: 12,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: `${spacing * 6}px 0 0`,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: colors.black,
      },
    },
    MuiPickersBasePicker: {
      container: {
        margin: -spacing * 6,
      },
      pickerView: {
        minWidth: 0,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.white,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: mainColor,
      },
      toolbarBtnSelected: {
        color: mainColor,
      },
    },
    MuiDrawer: {
      paperAnchorBottom: {
        paddingTop: spacing * 8,
        borderTopRightRadius: 16,
        borderTopLeftRadius: 16,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 40,
      },
      indicator: {
        backgroundColor: colors.black,
        minWidth: 'auto',
      },
      flexContainer: {
        height: '100%',
      },
    },
    MuiTab: {
      root: {
        minWidth: 'auto',
        padding: 0,
        marginRight: spacing * 6,
        minHeight: 'auto',
        height: '100%',
        transition: `${transitions.duration?.standard}s`,

        '&:hover': {
          color: colors.black,
        },

        '&:last-child': {
          marginRight: 0,
        },

        '@media (min-width: 600px)': {
          minWidth: 'auto',
        },
      },
      textColorInherit: {
        fontSize: 14,
        color: mainColor,
        fontWeight: 700,
        textTransform: 'none',
        opacity: 1,

        '&$selected': {
          color: colors.black,
        },
      },
      wrapper: {
        fontWeight: 700,
      },
    },
    MuiFab: {
      root: {
        width: 'auto',
        height: 'auto',
        minHeight: 'auto',
        backgroundColor: 'transparent',

        ...getHoverStyles({
          supportedDeviceStyles: {
            backgroundColor: 'rgba(255, 255, 255, .2)',
          },
        }),
      },
    },
  };
};
