import * as React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { colors } from '@core/theme/constants/colors';

export interface ButtonProps extends MuiButtonProps {
  text?: React.ReactNode;
  loading?: boolean;
  error?: boolean;
  component?: React.ComponentType<any>;
  componentProps?: any;
}

const Button: React.FC<ButtonProps> = ({
  text,
  loading,
  error,
  children,
  component: Component,
  componentProps,
  color = 'primary',
  ...otherProps
}) => {
  const baseProps: MuiButtonProps = {
    color,
    variant: 'contained',
    size: 'large',
    disabled: loading,
    style: error ? { backgroundColor: 'white', color: colors.error } : {}, // TODO move this logic to classes
    ...otherProps,
  };

  const progressColor = React.useMemo(() => {
    if (otherProps.variant) {
      return 'primary';
    }

    const config = {
      primary: 'secondary',
      secondary: 'primary',
    };

    return config[color];
  }, [color, otherProps.variant]);

  const spinner = loading ? (
    <CircularProgress color={progressColor} size={15} style={{ marginLeft: 8 }} />
  ) : null;

  const content = (
    <MuiButton {...baseProps}>
      {text || children} {spinner}
    </MuiButton>
  );

  if (Component) {
    return <Component {...componentProps}>{content}</Component>;
  }

  return content;
};

export default Button;
