import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/core';
import cx from 'classnames';

import styles from './FormUnit.styles';

export enum FormUnitMargin {
  none = 0,
  small = 12,
  medium = 16,
  large = 24,
}

export interface FormUnitProps extends WithStyles<typeof styles> {
  label?: React.ReactNode;
  component?: React.ReactNode;
  disabled?: boolean;
  margin?: FormUnitMargin;
  placeholder?: React.ReactNode;
  showPlaceholder?: boolean;
}

const FormUnit: React.FC<FormUnitProps> = (props) => {
  const { classes, label, component, children, disabled, margin, placeholder, showPlaceholder } =
    props;
  const hasComponent = Boolean(children || component);

  return (
    <Grid
      container
      className={classes.root}
      justify="flex-start"
      alignItems="center"
      wrap="nowrap"
      style={{ marginTop: margin }}
    >
      <span className={classes.label}>{label}</span>
      {showPlaceholder && <h6 className={classes.placeholder}>{placeholder}</h6>}
      {hasComponent && !showPlaceholder && (
        <div className={cx(classes.component, { [classes.componentDisabled]: disabled })}>
          {children || component}
        </div>
      )}
    </Grid>
  );
};

FormUnit.defaultProps = {
  margin: FormUnitMargin.small,
};

export default withStyles(styles)(FormUnit);
