import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ shortcuts, spacing }: Theme) {
  return createStyles({
    root: {
      width: 468,
      position: 'relative',
    },
    backBtn: {
      position: 'absolute',
      left: -32,
      top: -96,
    },
    forgotPasswordLink: {
      ...shortcuts.link.primary,
      marginBottom: -spacing(),
    },
    submitButton: {
      marginLeft: spacing(3),
    },
  });
}
