import * as React from 'react';
import { Link } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import LogoSmallIcon from './icons/LogoSmall';
import LogoBigIcon from './icons/LogoBig';

import styles from './Logo.styles';

type LogoSize = 'small' | 'big';

interface LogoProps extends WithStyles<typeof styles> {
  size?: LogoSize;
  link?: string;
}

const IconMap: { [K in LogoSize]: React.ComponentType<any> } = {
  small: LogoSmallIcon,
  big: LogoBigIcon,
};

const Logo: React.FC<LogoProps> = ({ classes, link, size = 'big' }) => {
  const Icon = IconMap[size];

  if (link) {
    return (
      <Link className={classes.root} to={link}>
        <Icon classes={{ root: classes.logo }} />
      </Link>
    );
  }

  return <Icon classes={{ root: classes.root }} />;
};

export default withStyles(styles)(Logo);
