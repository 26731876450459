import { USER_FIELDS } from '@Portal/shared/constants/user';
import Form from '@core/forms/base';

export default class NewPasswordForm extends Form {
  setup() {
    return {
      fields: [
        {
          name: USER_FIELDS.password,
          type: 'password',
          rules: 'required|passwordStrength',
        },
        {
          name: USER_FIELDS.newPassword,
          type: 'password',
          rules: `required|samePass:${USER_FIELDS.password}`,
        },
      ],
    };
  }
}
