import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

import { ThemeSettingsOptions } from './theme.types';
import { colors, spacing, opacity, transitions, radius, boxShadow } from './constants';
import { getShortcuts, getOverrides, props } from './modules';

const defaultThemeSettings = {
  fontFamily: '"Lato", sans-serif',
  mainColor: '#454E7E',
};

const getDefaultThemeWithSettings = (
  customSettings: ThemeSettingsOptions = defaultThemeSettings
): ThemeOptions => {
  const settings = { ...defaultThemeSettings, ...customSettings };
  const { mainColor, fontFamily } = settings;

  return {
    spacing,
    opacity,
    transitions,
    radius,
    boxShadow,
    props,
    shortcuts: getShortcuts(settings),
    overrides: getOverrides(settings),
    palette: {
      colors,
      primary: {
        main: mainColor,
      },
      secondary: {
        main: colors.secondary,
      },
      error: {
        main: colors.error,
      },
    },
    typography: {
      fontFamily,
    },
  };
};

export const createTheme = (theme?: ThemeSettingsOptions) => {
  return createMuiTheme(getDefaultThemeWithSettings(theme));
};
