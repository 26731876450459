import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { Link } from 'react-router-dom';

import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';
import LoginForm from './forms/login';
import TextField from '@shared/components/TextField';
import Form from '@shared/components/Form';
import Field from '@shared/components/Form/components/FormUnit';
import Logo from '@shared/components/Logo';
import AuthFormFooter from '@Portal/modules/Public/components/AuthFormFooter';
import Flex from '@shared/components/Flex';
import Button from '@shared/components/Button';
import LockIcon from '@shared/icons/Lock';
import { USER_FIELDS } from '@Portal/shared/constants/user';
import ROUTES from '@Portal/shared/constants/routes';

import styles from './Login.styles';

export interface LoginProps extends WithStyles<typeof styles> {}

@observer
class Login extends React.Component<LoginProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private form: BaseForm = new LoginForm();
  @observable private submitting = false;

  private login = async (data) => {
    this.submitting = true;

    try {
      await this.authStore.login(data);
    } catch (err) {
      throw err;
    } finally {
      this.submitting = false;
    }
  };

  private get fields() {
    return [
      {
        label: 'Email',
        component: (
          <TextField placeholder="Enter email" field={this.form.$(USER_FIELDS.username)} />
        ),
      },
      {
        label: 'Password',
        component: (
          <TextField
            withPasswordToggle
            placeholder="Enter password"
            field={this.form.$(USER_FIELDS.password)}
          />
        ),
      },
    ];
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.logoWrapper}>
          <Logo />
        </div>
        <Form withControls={false} formInstance={this.form} onSubmit={this.login}>
          {this.fields.map(({ label, component }) => (
            <Field label={label} component={component} key={label} />
          ))}
          <AuthFormFooter>
            <Button
              type="submit"
              text="Login"
              classes={{ root: classes.submitButton }}
              loading={this.submitting}
            />
            <Flex alignItems="center">
              <LockIcon />
              <Link to={ROUTES.public.forgotPassword} className={classes.forgotPasswordLink}>
                Forgot password?
              </Link>
            </Flex>
          </AuthFormFooter>
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(Login);
