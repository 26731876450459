import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';

import Message from '@shared/components/Message';
import { ButtonProps } from '@shared/components/Button';

import styles from './ErrorHandler.styles';

export interface ErrorHandlerProps extends WithStyles<typeof styles> {
  heading?: React.ReactNode;
  buttonProps?: Partial<ButtonProps>;
}

const handleReloadApp = () => {
  window.location.reload(true);
};

class ErrorHandler extends React.Component<ErrorHandlerProps> {
  static defaultProps = {
    heading: 'Something went wrong',
    buttonProps: {
      text: 'Reload application',
      onClick: handleReloadApp,
    },
  };

  render() {
    const { classes, heading, buttonProps } = this.props;

    return (
      <Message
        classes={{
          root: classes.root,
        }}
        heading={heading}
        buttonProps={{
          size: 'small',
          ...buttonProps,
        }}
      />
    );
  }
}

export default withStyles(styles)(ErrorHandler);
