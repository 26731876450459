import { createStyles, Theme } from '@material-ui/core';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {},
    label: {
      fontSize: 16,
      width: '20%',
      marginRight: spacing(3),
    },
    placeholder: {
      fontSize: 16,
      color: colors.grey2,
    },
    component: {
      fontSize: 16,
      flexGrow: 1,
      maxWidth: '80%',
    },
    componentDisabled: {
      opacity: 0.9,
      pointerEvents: 'none',
    },
  });
}
