import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import styles from './AuthHeading.styles';

export enum AuthHeadingPadding {
  none = 0,
  default = 16,
}

export interface HeadingProps extends WithStyles<typeof styles> {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  padding?: AuthHeadingPadding;
}

const AuthHeading: React.FC<HeadingProps> = (props) => {
  const { classes, padding, heading, subheading } = props;

  return (
    <div className={classes.root}>
      <h6 className={classes.heading}>{heading}</h6>
      <h6 className={classes.subheading} style={{ paddingBottom: padding }}>
        {subheading}
      </h6>
    </div>
  );
};

AuthHeading.defaultProps = {
  padding: AuthHeadingPadding.default,
};

export default withStyles(styles)(AuthHeading);
