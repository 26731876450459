import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';
import { UserRole } from '@Portal/shared/models/system-user';
import ROUTES from '@Portal/shared/constants/routes';

export const getDefaultRoute = () => {
  const authStore = container.get<AuthStore>(AuthStore.diToken);
  const paths = {
    [UserRole.admin]: ROUTES.private.clinics.path,
  };
  const { role } = authStore.user;

  return paths[role];
};
