import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';
import { RouteComponentProps } from 'react-router-dom';

import container from '@Portal/core/di-container';
import AuthStore from '@Portal/shared/stores/auth';
import NewPasswordForm from './forms/new-password';
import TextField from '@shared/components/TextField';
import Loading from '@shared/components/Loading';
import Form from '@shared/components/Form';
import Field from '@shared/components/Form/components/FormUnit';
import AuthHeading from '@shared/components/AuthHeading';
import ExpiredLink from '@shared/components/ExpiredLink';
import AuthFormFooter from '@Portal/modules/Public/components/AuthFormFooter';
import { showNotification } from '@shared/components/Notification';
import Button from '@shared/components/Button';
import { USER_FIELDS } from '@Portal/shared/constants/user';
import ROUTES from '@Portal/shared/constants/routes';
import { getQueries } from '@shared/utils/common';

import styles from './NewPassword.styles';

export interface NewPasswordProps extends WithStyles<typeof styles>, RouteComponentProps {}

enum ValidatingStatus {
  pending,
  failed,
  success,
}
@observer
class NewPassword extends React.Component<NewPasswordProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private form: BaseForm = new NewPasswordForm();
  @observable private validatingStatus = ValidatingStatus.pending;
  @observable private submitting = false;

  constructor(props: NewPasswordProps) {
    super(props);

    this.initiailize();
  }

  private get queries() {
    return getQueries({ decoder: (str) => str });
  }

  private initiailize = async () => {
    const { userid, token } = this.queries;

    if (userid && token) {
      try {
        this.validatingStatus = ValidatingStatus.pending;

        await this.authStore.validatePasswordRecoveryLink(userid, encodeURIComponent(token));

        this.validatingStatus = ValidatingStatus.success;
      } catch {
        this.validatingStatus = ValidatingStatus.failed;
      }
    }
  };

  private handleSubmit = async (data: { password: string; newPassword: string }) => {
    this.submitting = true;

    const { history } = this.props;
    const { userid, token } = this.queries;

    try {
      await this.authStore.setNewPassword(userid, {
        token,
        newPassword: data.newPassword,
      });

      showNotification('New password has been successfully set.');
      history.push(ROUTES.public.login);
    } catch (err) {
      throw err;
    } finally {
      this.submitting = false;
    }
  };

  private get fields() {
    const placeholder = '123ABCabc!@#';

    return [
      {
        label: 'New password',
        component: (
          <TextField
            withPasswordToggle
            placeholder={placeholder}
            field={this.form.$(USER_FIELDS.password)}
          />
        ),
      },
      {
        label: 'Confirm new password',
        component: (
          <TextField
            withPasswordToggle
            placeholder={placeholder}
            field={this.form.$(USER_FIELDS.newPassword)}
          />
        ),
      },
    ];
  }

  render() {
    const { userid, token } = this.queries;
    const { classes } = this.props;

    if (!userid || !token || this.validatingStatus === ValidatingStatus.failed) {
      return <ExpiredLink link={ROUTES.public.login} />;
    }

    if (this.validatingStatus === ValidatingStatus.pending) {
      return <Loading absolute />;
    }

    return (
      <div className={classes.root}>
        <AuthHeading subheading="Please create a new password" />
        <Form withControls={false} formInstance={this.form} onSubmit={this.handleSubmit}>
          {this.fields.map(({ label, component }) => (
            <Field label={label} component={component} key={label} />
          ))}
          <AuthFormFooter>
            <Button
              type="submit"
              text="Save new password"
              classes={{ root: classes.submitButton }}
              loading={this.submitting}
            />
          </AuthFormFooter>
        </Form>
      </div>
    );
  }
}

export default withStyles(styles)(NewPassword);
