interface AppConfig {
  environment?: EnvironmentType;
}

interface AppWindow extends Window {
  pocketDentist?: AppConfig;
}

export const getEnvironment = () => {
  return (window as AppWindow)?.pocketDentist?.environment;
};
