import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Button from '@shared/components/Button';
import { Link } from 'react-router-dom';

import styles from './NoMatch.styles';

export interface NoMatchProps extends WithStyles {
  loggedIn: boolean;
  forbidden?: boolean;
  redirectLink?: string;
}

const NoMatch: React.FC<NoMatchProps> = ({ classes, forbidden, redirectLink, loggedIn }) => {
  const message = forbidden
    ? 'Sorry you don’t have permission to view this page'
    : 'We’re unable to find the page you’re looking for';

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      justify="center"
      alignItems="center"
    >
      <div>
        <h5 className={classes.title}>{forbidden ? 'No permission' : 'Page not found'}</h5>
        <h6 className={classes.message}>{message}</h6>
        <Button
          component={Link}
          componentProps={{ to: redirectLink }}
          text={loggedIn ? 'Go to home page' : 'Go to login page'}
        />
      </div>
    </Grid>
  );
};

export default withStyles(styles)(NoMatch);

NoMatch.defaultProps = {
  redirectLink: '/',
};
