import { injectable } from 'inversify';

import { getEnvironment } from '@shared/utils/env';
import config from './config.json';

type InitialConfig = typeof config;

interface AppConfig {
  apiURL: {
    origin: string;
    pathname: string;
  };
  domain?: string;
}

interface CommonAppConfig {
  version: string;
}

interface ConfigType {
  clinic: AppConfig;
  portal: AppConfig;
  common: CommonAppConfig;
}

@injectable()
export default class Config {
  static diToken = Symbol('config');

  private config: ConfigType;

  initialize() {
    this.set();
  }

  get() {
    return this.config;
  }

  private set() {
    const { clinic, portal, common } = config;
    const env = getEnvironment();

    const getAppConfig = (source: InitialConfig['clinic'] | InitialConfig['portal']): AppConfig => {
      return env ? source[env] : source.Local;
    };

    this.config = {
      common,
      clinic: getAppConfig(clinic),
      portal: getAppConfig(portal),
    };
  }
}
