import Axios, { AxiosRequestConfig } from 'axios';
import { injectable } from 'inversify';
import qs from 'qs';

import { NotificationType, showNotification } from '@shared/components/Notification';
import { browser } from '@shared/utils/browser';

Axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat', encode: false });
};

interface Config {
  defaults: Partial<AxiosRequestConfig>;
  getAccessToken: () => string | undefined;
  refreshToken: () => Promise<any>;
}

@injectable()
export default class HTTPClient {
  static diToken = Symbol('http-client');

  private getAccessToken: Config['getAccessToken'];
  private refreshToken: Config['refreshToken'];

  initialize(config: Config) {
    this.setDefaults(config.defaults);
    this.refreshToken = config.refreshToken;
    this.getAccessToken = config.getAccessToken;
    this.setRequestInterceptors();
    this.setResponseInterceptors();
  }

  private get authHeader() {
    const token = this.getAccessToken();

    return token ? `Bearer ${token}` : '';
  }

  private setDefaults(defaults: Partial<AxiosRequestConfig> = {}) {
    Axios.defaults.baseURL = defaults.baseURL;
    Axios.defaults.headers = {
      ...defaults.headers,
      'Content-Type': 'application/json',
    };

    if (browser?.name == 'ie') {
      Axios.defaults.headers.Pragma = 'no-cache';
    }
  }

  private setRequestInterceptors() {
    Axios.interceptors.request.use(
      (config: AxiosRequestConfig): AxiosRequestConfig => {
        if (this.authHeader) {
          config.headers.Authorization = this.authHeader;
        }

        return config;
      },
      (error: Error): Error => {
        throw error;
      }
    );
  }

  private setResponseInterceptors() {
    Axios.interceptors.response.use(
      (response) => ({ ...response, data: response.data.data || response.data }),
      async (error) => {
        const response = error ? error.response : undefined;

        if (!response) {
          return;
        }

        if (response.status === 403) {
          this.processForbidden();
        }

        if (response.status === 401) {
          try {
            await this.refreshToken();
            response.config.headers.Authorization = this.authHeader;

            return Axios(response.config);
          } catch {}
        }

        if (response.status >= 500) {
          showNotification('Failed to execute operation', NotificationType.error);
        }

        throw error;
      }
    );
  }

  private processForbidden(msg = '', showMessage = true) {
    if (showMessage) {
      // showNotification(msg);
    }
  }
}
