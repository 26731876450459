import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Button from '@shared/components/Button';
import ExpiredLinkIcon from '@shared/icons/ExpiredLink';

import styles from './ExpiredLink.styles';

export interface ExpiredLinkProps extends WithStyles<typeof styles> {
  link: string;
  buttonText?: React.ReactNode;
}

const ExpiredLink: React.FC<ExpiredLinkProps> = ({ classes, buttonText, link }) => {
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <ExpiredLinkIcon classes={{ root: classes.icon }} />
        <h4 className={classes.heading}>The link has expired</h4>
        <h6 className={classes.subheading}>This link is no longer active.</h6>
      </div>
      <Link to={link} className={classes.button}>
        <Button text={buttonText} classes={{ root: classes.button }} />
      </Link>
    </div>
  );
};

ExpiredLink.defaultProps = {
  buttonText: 'Go to login page',
};

export default withStyles(styles)(ExpiredLink);
