import * as React from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import styles from './Add.styles';

interface AddIconProps extends Omit<SvgIconProps, 'classes'>, WithStyles<typeof styles> {}

const AddIcon: React.FC<AddIconProps> = ({ classes }) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" classes={{ root: classes.root }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.99382e-07C3.58172 1.08564e-06 -1.08564e-06 3.58172 -6.99382e-07 8C-3.13124e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 3.13124e-07 8 6.99382e-07ZM8 12C7.44771 12 7 11.5523 7 11L7 10C7 9.44772 6.55228 9 6 9L5 9C4.44771 9 4 8.55229 4 8C4 7.44772 4.44771 7 5 7L6 7C6.55228 7 7 6.55229 7 6L7 5C7 4.44772 7.44771 4 8 4C8.55228 4 9 4.44772 9 5L9 6C9 6.55229 9.44771 7 10 7L11 7C11.5523 7 12 7.44772 12 8C12 8.55229 11.5523 9 11 9L10 9C9.44771 9 9 9.44772 9 10L9 11C9 11.5523 8.55228 12 8 12Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default withStyles(styles)(AddIcon);
