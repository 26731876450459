import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { browser } from '@shared/utils/browser';

export const getHoverStyles = (styles: {
  supportedDeviceStyles?: CSSProperties;
  defaultStyles?: CSSProperties;
}) => {
  return {
    ...styles.defaultStyles,

    '@media (hover: hover)': {
      '&:hover': styles.supportedDeviceStyles,
    },

    '@media (hover: none)': {
      '&:hover': styles.defaultStyles,
    },

    ...(browser?.name == 'ie'
      ? {
          '&:hover': styles.supportedDeviceStyles,
        }
      : {}),
  };
};
