import { createStyles, Theme } from '@material-ui/core/styles';

export default function styles({ spacing, palette: { colors } }: Theme) {
  return createStyles({
    root: {
      marginBottom: spacing(4),
    },
    heading: {
      fontSize: 24,
      fontWeight: 700,
      color: colors.black,
    },
    subheading: {
      fontWeight: 400,
      fontSize: 16,
      padding: spacing(4, 0),
    },
  });
}
