import { createStyles, Theme } from '@material-ui/core';

export default function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      position: 'relative',
    },
    children: {},
    controls: {},
    buttonControl: {},
    controlsFormUnitComponent: {},
    controlsFormUnitComponentFullWidth: {
      maxWidth: '100%',
    },
    controlsFormUnitLabelFullWidth: {
      display: 'none',
    },
    controlsFormUnitLabel: {},
    submitButton: {
      marginRight: spacing(4),
    },
  });
}
