import validatorjs, { ValidatorStatic } from 'validatorjs';
import Form from 'mobx-react-form';
import moment from 'moment';

import {
  PATIENT_MINIMUM_ACCEPTABLE_BIRTH_DATE,
  PATIENT_MIN_AGE,
} from '@Clinic/shared/constants/user';

export interface Validations {
  [key: string]: {
    function: (value: any, attribute?: string) => boolean;
    message: string;
  };
}

export const validationRegExpr = {
  oneLowerCase: /[a-z]/,
  oneUpperCase: /[A-Z]/,
  oneDigital: /[0-9]/,
  specialChar: /[`~!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  specialAndDigitChar: /[0-9`~!@#$%^&*()+=_\-{}\[\]|:;“’?/<>,.]/,
  minimumLength: /^.{8,}$/,
  ampm: /\b((1[0-2]|0?[1-9]):([0-5][0-9]))/,
  time: /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
  phoneNumber: /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4,9}$/,
  url: /^(?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/,
};

const baseValidations: Validations = {
  passwordStrength: {
    function(value) {
      const formatValidators = [
        { validation: validationRegExpr.oneLowerCase },
        { validation: validationRegExpr.oneUpperCase },
        { validation: validationRegExpr.oneDigital },
        { validation: validationRegExpr.specialChar },
        { validation: validationRegExpr.minimumLength, mandatory: true },
      ];
      const minimumNonMandatoryValidatorsToSatisfy = 3;
      const nonMandatoryValidators = formatValidators.filter(({ mandatory }) => !mandatory);
      const mandatoryValidators = formatValidators.filter(({ mandatory }) => mandatory);

      const mandatoryValidatorsSatisfied = mandatoryValidators.every(({ validation }) =>
        validation.test(value)
      );
      const nonMandatorySatisfiedValidatorsAmount = nonMandatoryValidators.filter(
        ({ validation }) => validation.test(value)
      ).length;
      const nonMandatoryValidatorsSatisfied =
        nonMandatorySatisfiedValidatorsAmount >= minimumNonMandatoryValidatorsToSatisfy;

      return mandatoryValidatorsSatisfied && nonMandatoryValidatorsSatisfied;
    },
    message: 'Invalid password format.',
  },
  samePass: {
    function(value, attribute) {
      const newValue = (this as Form).validator.input[attribute];

      return newValue === value;
    },
    message: 'Passwords do not match.',
  },
  array: {
    function(value) {
      return Boolean(value.length);
    },
    message: 'This field is required.',
  },
  number: {
    function(value) {
      return !isNaN(Number(value));
    },
    message: 'Invalid number format.',
  },
  positive: {
    function(value) {
      return Number(value) > 0;
    },
    message: 'This field should be positive.',
  },
  integer: {
    function(value) {
      return Number.isInteger(Number(value));
    },
    message: 'This field should be integer.',
  },
  withoutSpecialAndDigitChars: {
    function(value) {
      return !validationRegExpr.specialAndDigitChar.test(value);
    },
    message: 'The field cannot contain digits and special characters.',
  },
  withoutSpecialChars: {
    function(value) {
      return !validationRegExpr.specialChar.test(value);
    },
    message: 'The field cannot contain special characters.',
  },
  ampm: {
    function(value: string) {
      return validationRegExpr.ampm.test(value);
    },
    message: 'Wrong am/pm format.',
  },
  time: {
    function(value: string) {
      return validationRegExpr.time.test(value);
    },
    message: 'Wrong time format (e.g 12:00).',
  },
  phoneNumber: {
    function(value: string) {
      return validationRegExpr.phoneNumber.test(value);
    },
    message: 'The format is invalid. (e.g +44 7440 961500)',
  },
  url: {
    function(value: string) {
      return validationRegExpr.url.test(value);
    },
    message: 'The format is invalid. (example.com)',
  },
  patient_birth_date: {
    function(value: string) {
      return moment.utc(value).isSameOrBefore(PATIENT_MINIMUM_ACCEPTABLE_BIRTH_DATE);
    },
    message: `You must be ${PATIENT_MIN_AGE} years of age or above to register.`,
  },
  disable_future: {
    function(value: string) {
      return moment.utc(value).isBefore(new Date().toISOString());
    },
    message: 'You cannot select future date.',
  },
};

export default function getPluginsConfig(customValidations?: Validations) {
  const rules = {
    ...baseValidations,
    ...customValidations,
  };

  return {
    dvr: {
      package: validatorjs,
      extend: (validator: ValidatorStatic) => {
        Object.keys(rules).forEach((key) =>
          validator.register(key, rules[key].function, rules[key].message)
        );

        const messages = validator.getMessages('en');

        messages.min = 'Please, provide at least :min characters.';
        messages.max = "The field shouldn't contain more than :max characters.";
        messages.date = 'The date format is invalid.';
        messages.required = 'This field is required';
        messages.required_with = 'This field is required when :required_with is not empty';

        validator.setMessages('en', messages);
      },
    },
  };
}
